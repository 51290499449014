import * as React from "react";
import * as ReactDOM from "react-dom";
import * as sanitizeHtml from "sanitize-html";
import { IOptions } from "sanitize-html";

interface IERange {
  moveToElementText: (el: HTMLElement) => void;
  collapse: (shouldCollapse: boolean) => void;
  select: () => void;
}

interface IEHTMLElement extends HTMLElement {
  createTextRange: () => IERange;
}

interface Props {
  initialContent: string;
  placeholder: string;
  onInputBlur: (textValue: string) => void;
  onInputKeyUp: () => void;
  className: string;
}

interface State {
  content: string;
}

export default class TextareaContentEditable extends React.PureComponent<
  Props,
  State
> {
  static defaultProps = {
    className: "",
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      content: props.initialContent,
    };
  }

  forceUpdateContent = (content: string): void => {
    this.setState({
      content,
    });
  };

  getTextValue = (): string => {
    const node = ReactDOM.findDOMNode(this) as HTMLElement;

    if (node) {
      return node.innerText;
    }

    return "";
  };

  //TODO shouldn't be able to control focus from outside the Component
  focus = (): void => {
    const node = ReactDOM.findDOMNode(this) as HTMLElement;

    if (node) {
      node.focus();
      this.placeCaretAtEnd(node);
    }
  };

  placeCaretAtEnd = (el: HTMLElement): void => {
    el.focus();
    if (
      typeof window.getSelection !== "undefined" &&
      typeof document.createRange !== "undefined"
    ) {
      let range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      let sel = window.getSelection();
      if (sel !== null) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    } else if (
      typeof (document.body as IEHTMLElement).createTextRange !== "undefined"
    ) {
      //TODO research if this is in any of our supported browsers and document.createRange is not - can remove if createRange is supported
      //TODO have to support older clients (IE) where .createTextRange does not exist in API - https://github.com/Microsoft/TypeScript/issues/10181
      let textRange = (document.body as IEHTMLElement).createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  };

  handleBlur = (): void => {
    const { onInputBlur } = this.props;

    if (onInputBlur) {
      onInputBlur(this.getTextValue());
    }
  };

  handleKeyUp = (): void => {
    const { onInputKeyUp } = this.props;

    if (onInputKeyUp) {
      onInputKeyUp();
    }
  };

  getSanitizeOptions = (): IOptions => {
    return {
      allowedTags: [],
      allowedAttributes: {},
    };
  };

  render() {
    const { content } = this.state;
    const { placeholder, className } = this.props;

    const classNames: Array<string> = [
      "ddbc-textarea-content-editable",
      className,
    ];

    return (
      <div
        className={classNames.join(" ")}
        contentEditable={true}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content, this.getSanitizeOptions()),
        }}
        onBlur={this.handleBlur}
        onKeyUp={this.handleKeyUp}
      />
    );
  }
}
