import React from "react";
import { connect, DispatchProp } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { Tooltip } from "@dndbeyond/character-common-components/es";
import {
  CampaignSummary,
  FeatureFlagContext,
  LightBuilderSvg,
  LightChatBubbleSvg,
  LightPlayButtonSvg,
  ThemedLongRestSvg,
  ThemedShareSvg,
  ThemedShortRestSvg,
} from "@dndbeyond/character-components/es";
import {
  CampaignDataContract,
  CharacterPreferences,
  characterSelectors,
  CharacterStatusSlug,
  CharacterTheme,
  Constants,
  Item,
  ItemUtils,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";
import D6 from "@dndbeyond/fontawesome-cache/svgs/regular/dice-d6.svg";
import { GameLogNotificationWrapper } from "@dndbeyond/game-log-components";

import { Link } from "~/components/Link";

import { sidebarActions } from "../../../Shared/actions";
import { appEnvSelectors } from "../../../Shared/selectors";
import { GameLogState } from "../../../Shared/stores/typings";
import { PaneComponentEnum } from "../../../Shared/utils";
import { NavigationUtils } from "../../../Shared/utils";
import { sheetActions } from "../../actions/sheet";
import WatchTourDialog from "../../components/WatchTourDialog";
import ClaimConfirmationDialog from "../../components/WatchTourDialog/ClaimConfirmationDialog";
import { sheetAppSelectors } from "../../selectors";
import { SheetAppState } from "../../typings";
import CharacterHeaderInfo from "../CharacterHeaderInfo";

interface Props extends DispatchProp {
  campaign: CampaignDataContract | null;
  builderUrl: string;
  items: Array<Item>;
  preferences: CharacterPreferences;
  theme: CharacterTheme;
  isReadonly: boolean;
  gameLog: GameLogState;
  status: CharacterStatusSlug | null;
}

class CharacterHeaderDesktop extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleCampaignShow = (): void => {
    const { dispatch } = this.props;
    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.CAMPAIGN));
  };

  handleShortResetClick = (evt: React.MouseEvent): void => {
    const { dispatch } = this.props;

    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.SHORT_REST));
  };

  handleGameLogClick = (evt: React.MouseEvent): void => {
    const { dispatch } = this.props;

    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.GAME_LOG));
  };

  handleLongResetClick = (evt: React.MouseEvent): void => {
    const { dispatch } = this.props;

    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.LONG_REST));
  };

  handleShareClick = (evt: React.MouseEvent): void => {
    const { dispatch } = this.props;

    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    dispatch(sheetActions.shareUrl());
  };

  hasMagicItem = (): boolean => {
    const { items } = this.props;

    return !!items.find((item) => ItemUtils.isMagic(item));
  };

  renderSideContent = (): React.ReactNode => {
    const {
      builderUrl,
      campaign,
      preferences,
      isReadonly,
      theme,
      gameLog,
      status,
    } = this.props;

    if (isReadonly) {
      if (status === CharacterStatusSlug.PREMADE) {
        return <WatchTourDialog />;
      }

      if (!campaign) {
        return null;
      }

      return (
        <div className="ct-character-header-desktop__group ct-character-header-desktop__group--campaign">
          <CampaignSummary
            campaign={campaign}
            onCampaignShow={this.handleCampaignShow}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        {preferences !== null &&
          preferences.privacyType ===
            Constants.PreferencePrivacyTypeEnum.PUBLIC && (
            <div className="ct-character-header-desktop__group ct-character-header-desktop__group--share">
              <div
                className="ct-character-header-desktop__button"
                onClick={this.handleShareClick}
                role="button"
                tabIndex={0}
              >
                <div className="ct-character-header-desktop__button-icon">
                  <ThemedShareSvg theme={theme} />
                </div>
                <span className="ct-character-header-desktop__button-label">
                  Share
                </span>
              </div>
            </div>
          )}
        <div className="ct-character-header-desktop__group ct-character-header-desktop__group--short-rest">
          <div
            className="ct-character-header-desktop__button"
            onClick={this.handleShortResetClick}
            role="button"
            tabIndex={0}
          >
            <div className="ct-character-header-desktop__button-icon">
              <ThemedShortRestSvg theme={theme} />
            </div>
            <span className="ct-character-header-desktop__button-label">
              Short Rest
            </span>
          </div>
        </div>
        <div className="ct-character-header-desktop__group ct-character-header-desktop__group--long-rest">
          <div
            className="ct-character-header-desktop__button"
            onClick={this.handleLongResetClick}
            role="button"
            tabIndex={0}
          >
            <div className="ct-character-header-desktop__button-icon">
              <ThemedLongRestSvg theme={theme} />
            </div>
            <span className="ct-character-header-desktop__button-label">
              Long Rest
            </span>
          </div>
        </div>
        {/*{this.hasMagicItem() &&*/}
        {/*<div className="ct-character-header-desktop__group">*/}
        {/*<div className="ct-character-header-desktop__button" onClick={this.handleDawnClick}>*/}
        {/*<i className="i-menu-dawn" />*/}
        {/*<span className="ct-character-header-desktop__button-label">Dawn</span>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*}*/}
        {campaign !== null && (
          <div className="ct-character-header-desktop__group ct-character-header-desktop__group--campaign">
            <CampaignSummary
              campaign={campaign}
              onCampaignShow={this.handleCampaignShow}
            />
            <Tooltip title="Launch Game" isDarkMode={theme.isDarkMode}>
              <Link
                href={NavigationUtils.getLaunchGameUrl(campaign)}
                target="maps"
                aria-label="Launch Game"
              >
                <div className="ct-character-header__group--game-log">
                  <div className="ct-character-header-desktop__button-icon">
                    <LightPlayButtonSvg />
                  </div>
                </div>
              </Link>
            </Tooltip>
            <GameLogNotificationWrapper
              themeColor={theme.themeColor}
              gameLogIsOpen={gameLog.isOpen}
              notificationOnClick={this.handleGameLogClick}
            >
              <Tooltip title="Game Log" isDarkMode={theme.isDarkMode}>
                <div
                  role="button"
                  aria-roledescription="Game Log"
                  className="ct-character-header__group--game-log ct-character-header__group--game-log-last"
                  onClick={this.handleGameLogClick}
                >
                  <div className="ct-character-header-desktop__button-icon">
                    <FeatureFlagContext.Consumer>
                      {({ tacticalMapsDmOnlyInvisDiceFlag }) =>
                        tacticalMapsDmOnlyInvisDiceFlag ? (
                          <D6 className="ddbc-svg ddbc-gamelog-svg" />
                        ) : (
                          <LightChatBubbleSvg />
                        )
                      }
                    </FeatureFlagContext.Consumer>
                  </div>
                </div>
              </Tooltip>
            </GameLogNotificationWrapper>
          </div>
        )}
        <div className="ct-character-header-desktop__group ct-character-header-desktop__group--builder">
          <Tooltip
            isDarkMode={theme.isDarkMode}
            title="Go to builder"
            className="ct-character-header-desktop__builder"
          >
            <RouterLink
              to={builderUrl}
              className="ct-character-header-desktop__builder-link"
              aria-label="Go to builder"
            >
              <LightBuilderSvg />
            </RouterLink>
          </Tooltip>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="ct-character-header-desktop">
        <div className="ct-character-header-desktop__group ct-character-header-desktop__group-tidbits">
          <CharacterHeaderInfo />
        </div>
        <div className="ct-character-header-desktop__group ct-character-header-desktop__group--gap" />
        {this.renderSideContent()}
      </div>
    );
  }
}

function mapStateToProps(state: SheetAppState) {
  return {
    builderUrl: sheetAppSelectors.getBuilderUrl(state),
    campaign: rulesEngineSelectors.getCampaign(state),
    items: rulesEngineSelectors.getInventory(state),
    preferences: rulesEngineSelectors.getCharacterPreferences(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
    isReadonly: appEnvSelectors.getIsReadonly(state),
    gameLog: appEnvSelectors.getGameLog(state),
    status: characterSelectors.getStatusSlug(state),
  };
}

export default connect(mapStateToProps)(CharacterHeaderDesktop);
