import * as React from "react";

import { HtmlContent } from "~/components/HtmlContent";

interface Props {
  content: string;
  showLabelOverride?: string;
  hideLabelOverride?: string;
  clsNames: Array<string>;
  className: string;
  maxDescriptionLength: number;
}

interface State {
  active: boolean;
}

export default class TruncatedContent extends React.PureComponent<
  Props,
  State
> {
  static defaultProps = {
    maxDescriptionLength: 600,
    className: "",
    clsNames: [],
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  handleToggleClick = (): void => {
    this.setState((prevState: State, props: Props) => ({
      active: !prevState.active,
    }));
  };

  render() {
    const { active } = this.state;
    const {
      content,
      showLabelOverride,
      hideLabelOverride,
      clsNames,
      className,
      maxDescriptionLength,
    } = this.props;

    let conClsNames: Array<string> = [
      "truncated-content",
      "ddbc-truncated-content",
      className,
    ];
    if (active) {
      conClsNames.push("truncated-content-shown");
      conClsNames.push("ddbc-truncated-content--is-shown");
    } else {
      conClsNames.push("truncated-content-hidden");
      conClsNames.push("ddbc-truncated-content--is-hidden");
    }

    if (clsNames) {
      conClsNames = [...conClsNames, ...clsNames];
    }

    const showLabel: string = showLabelOverride
      ? showLabelOverride
      : "Show More";
    const hideLabel: string = hideLabelOverride
      ? hideLabelOverride
      : "Show Less";

    if (content.length <= maxDescriptionLength) {
      return <HtmlContent className={conClsNames.join(" ")} html={content} />;
    }

    return (
      <div className={conClsNames.join(" ")}>
        <div className="truncated-content-content ddbc-truncated-content__content">
          <HtmlContent
            className="ddbc-truncated-content__content-text"
            html={content}
          />
        </div>
        <div
          className="truncated-content-trigger ddbc-truncated-content__trigger"
          onClick={this.handleToggleClick}
        >
          <span className="truncated-content-trigger-label ddbc-truncated-content__trigger-label">
            {active ? hideLabel : showLabel}
          </span>
        </div>
      </div>
    );
  }
}
