import clsx from "clsx";
import { FC, HTMLAttributes } from "react";

import { Tooltip } from "@dndbeyond/character-common-components/es";
import {
  Item,
  Constants,
  ItemUtils,
} from "@dndbeyond/character-rules-engine/es";

import { AttunementIcon } from "~/tools/js/smartComponents/Icons";

import { useCharacterTheme } from "../../contexts/characterTheme";

//TODO ItemName should have the concept of how to render attunmentIcon rather than being passed a node

interface ItemNameProps extends HTMLAttributes<HTMLSpanElement> {
  item: Item;
  className?: string;
  onClick?: () => void;
  showAttunement?: boolean;
  showLegacy?: boolean;
}

export const ItemName: FC<ItemNameProps> = ({
  showAttunement = true,
  showLegacy = false,
  className,
  onClick,
  item,
  ...props
}) => {
  const { isDarkMode } = useCharacterTheme();
  const requiresAttunement = item.modifiers?.some(
    (mod) => mod.requiresAttunement
  );

  const handleClick = (e: React.MouseEvent): void => {
    if (onClick) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      onClick();
    }
  };

  const getDisplayName = () => {
    const name = ItemUtils.getName(item);
    if (name === null) return ItemUtils.getDefinitionName(item);
    if (!name) return "Item";
    return name;
  };

  const getItemRarity = () => {
    switch (ItemUtils.getRarity(item)) {
      case Constants.ItemRarityNameEnum.ARTIFACT:
        return "artifact";
      case Constants.ItemRarityNameEnum.LEGENDARY:
        return "legendary";
      case Constants.ItemRarityNameEnum.VERY_RARE:
        return "very-rare";
      case Constants.ItemRarityNameEnum.RARE:
        return "rare";
      case Constants.ItemRarityNameEnum.UNCOMMON:
        return "uncommon";
      case Constants.ItemRarityNameEnum.COMMON:
      default:
        return "common";
    }
  };

  return (
    <>
      <span
        className={clsx([
          "ddbc-item-name",
          `ddbc-item-name--rarity-${getItemRarity()}${
            isDarkMode ? "--dark-mode" : ""
          }`,
          className,
        ])}
        onClick={handleClick}
        {...props}
      >
        {getDisplayName()}
        {item.isCustomized && (
          <Tooltip
            title="Item is Customized"
            className="ddbc-item-name__asterisk"
            isDarkMode={isDarkMode}
          >
            *
          </Tooltip>
        )}
        {requiresAttunement && showAttunement && item.isAttuned && (
          <span className="ddbc-item-name__icon ddbc-item-name__icon--attuned">
            <AttunementIcon isDarkMode={isDarkMode} />
          </span>
        )}
        {showLegacy && ItemUtils.isLegacy(item) && (
          <span className="ddbc-item-name__legacy">(Legacy)</span>
        )}
      </span>
    </>
  );
};
