import styles from "./styles.module.css";

interface Props {
  includeTitle?: boolean;
  useLinks?: boolean;
  useMyCharactersLink?: boolean;
}

export const MaxCharactersMessageText = ({
  includeTitle,
  useLinks,
  useMyCharactersLink,
}: Props) => {
  const subscribe = useLinks ? (
    <a className={styles.link} href="/store/subscribe">
      Subscribe
    </a>
  ) : (
    "Subscribe"
  );
  const myCharacters =
    useMyCharactersLink || useLinks ? (
      <a className={styles.link} href="/characters">
        My Characters
      </a>
    ) : (
      "My Characters"
    );

  return (
    <>
      {includeTitle && (
        <p className={styles.messageTitle}>
          Oops! Your character slots are full.
        </p>
      )}
      <p className={styles.messageSubtext}>
        You&apos;re quite the adventurer! {subscribe} to unlock additional
        slots, or return to {myCharacters} and delete a character to make room.
      </p>
    </>
  );
};
