import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import React from "react";
import { connect, DispatchProp } from "react-redux";

import { SourceAbbr } from "@dndbeyond/character-common-components/es";
import { DdbBadgeSvg } from "@dndbeyond/character-components/es";
import {
  characterActions,
  CharacterTheme,
  Constants,
  DefinitionPool,
  DefinitionUtils,
  Feat,
  FeatUtils,
  Race,
  RaceDefinitionContract,
  RaceUtils,
  RacialTraitContract,
  RacialTraitUtils,
  RuleData,
  RuleDataUtils,
  rulesEngineSelectors,
  serviceDataSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { Accordion } from "~/components/Accordion";
import { HtmlContent } from "~/components/HtmlContent";
import { LegacyBadge } from "~/components/LegacyBadge";
import { SummaryList } from "~/components/SummaryList";

import { modalActions } from "../../../../Shared/actions";
import { FullscreenModal } from "../../../../Shared/components/common/FullscreenModal";
import { Collapsible } from "../../../../Shared/components/legacy/common/Collapsible";
import { modalSelectors } from "../../../../Shared/selectors";
import { TypeScriptUtils } from "../../../../Shared/utils";
import PageHeader from "../../../components/PageHeader";
import { builderSelectors } from "../../../selectors";
import styles from "./styles.module.css";

interface SpeciesTraitProps {
  speciesTrait: RacialTraitContract;
}
class SpeciesConfirmSpeciesTrait extends React.PureComponent<SpeciesTraitProps> {
  render() {
    const { speciesTrait } = this.props;

    const description = RacialTraitUtils.getDescription(speciesTrait);
    const name = RacialTraitUtils.getName(speciesTrait);

    return (
      <Collapsible
        trigger={name}
        clsNames={["race-detail-racial-trait-name"]}
        initiallyCollapsed={false}
      >
        <HtmlContent
          className="race-detail-racial-trait-description"
          html={description ? description : ""}
          withoutTooltips
        />
      </Collapsible>
    );
  }
}

interface SpeciesChooseContentProps {
  species: RaceDefinitionContract;
  ruleData: RuleData;
  definitionPool: DefinitionPool;
  theme: CharacterTheme;
  summaryTitle: string;
}
export class SpeciesChooseContent extends React.PureComponent<SpeciesChooseContentProps> {
  render() {
    const { species, ruleData, summaryTitle } = this.props;

    const fullName = RaceUtils.getFullName(species);
    const isLegacy = RaceUtils.getIsLegacy(species);
    const portraitAvatarUrl = RaceUtils.getPortraitAvatarUrl(species);
    const description = RaceUtils.getDescription(species);
    const moreDetailsUrl = RaceUtils.getMoreDetailsUrl(species);
    const speciesTraits = RaceUtils.getDefinitionRacialTraits(species);
    const sources = RaceUtils.getSources(species);
    const simulatedSpeciesTraits = speciesTraits.map((speciesTrait) =>
      RacialTraitUtils.simulateRacialTraitFromContract(speciesTrait)
    );
    const calledOutSpeciesTraits = RaceUtils.deriveCalledOutRacialTraits(
      simulatedSpeciesTraits,
      ruleData
    );
    // TODO eventually we should simulate a species, but not going to add that at this point
    // TODO eventually pull in SummaryList once species or speciesTraits can be fully simulated with modifiers
    let orderedSpeciesTraits: RacialTraitContract[] = speciesTraits
      ? RaceUtils.deriveOrderedRacialTraits(speciesTraits)
      : [];
    let visibleSpeciesTraits: RacialTraitContract[] =
      RaceUtils.deriveVisibleRacialTraits(
        orderedSpeciesTraits,
        Constants.AppContextTypeEnum.BUILDER
      );
    visibleSpeciesTraits =
      RacialTraitUtils.filterRacialTraitsByDisplayConfigurationType(
        visibleSpeciesTraits,
        [Constants.DisplayConfigurationTypeEnum.RACIAL_TRAIT]
      );

    const definitionKey = DefinitionUtils.hack__generateDefinitionKey(
      RaceUtils.getEntityRaceTypeId(species),
      RaceUtils.getEntityRaceId(species)
    );
    const builderText = RuleDataUtils.getBuilderHelperTextByDefinitionKeys(
      [definitionKey],
      ruleData,
      Constants.DisplayConfigurationTypeEnum.RACIAL_TRAIT
    );

    const previewUrl: string | null =
      portraitAvatarUrl ?? RuleDataUtils.getDefaultRaceImageUrl(ruleData);

    const descriptionClassNames: string[] = ["race-detail-description"];

    if (calledOutSpeciesTraits.length > 0) {
      descriptionClassNames.push("race-detail-description--hide-traits");
    }

    return (
      <div className="confirm-race race-detail">
        <div className="race-detail-primary">
          <div className="race-detail-aside">
            <div className="race-detail-preview">
              <img
                className="race-detail-preview-img"
                src={previewUrl ? previewUrl : ""}
                alt=""
              />
            </div>
          </div>
          <PageHeader>
            {fullName} {isLegacy && <LegacyBadge id={fullName || ""} />}
          </PageHeader>
          <div className="race-detail-sources">
            {sources
              .map((sourceMapping) =>
                RuleDataUtils.getSourceDataInfo(
                  sourceMapping.sourceId,
                  ruleData
                )
              )
              .filter(TypeScriptUtils.isNotNullOrUndefined)
              .map((source, idx) => {
                return (
                  <React.Fragment>
                    {idx > 0 ? " /" : ""}{" "}
                    <SourceAbbr sourceName={source.description} />
                  </React.Fragment>
                );
              })}
          </div>
          <HtmlContent
            className={descriptionClassNames.join(" ")}
            html={description ? description : ""}
            withoutTooltips
          />
          {calledOutSpeciesTraits.length > 0 && (
            <SummaryList
              list={calledOutSpeciesTraits}
              title={summaryTitle + " Traits"}
              className={styles.summaryList}
            />
          )}
          <div className="race-detail-more">
            <a
              className="race-detail-more-link"
              href={moreDetailsUrl ? moreDetailsUrl : ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {fullName} Details Page <ArrowRightAltIcon />
            </a>
          </div>
        </div>
        <div className="race-detail-secondary">
          <h3 className="race-detail-secondary-traits-title">
            {fullName} Traits
          </h3>
          {builderText.map((helperText, idx) => (
            <Accordion
              key={`${helperText.label}-${idx}`}
              summary={
                <>
                  <DdbBadgeSvg /> {helperText.label}
                </>
              }
            >
              <HtmlContent html={helperText.description} />
            </Accordion>
          ))}
          {visibleSpeciesTraits.length > 0 && (
            <div className="race-detail-racial-traits">
              {visibleSpeciesTraits.map((speciesTrait) => (
                <SpeciesConfirmSpeciesTrait
                  key={RacialTraitUtils.getId(speciesTrait)}
                  speciesTrait={speciesTrait}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

interface Props extends DispatchProp {
  species: Race | null;
  feats: Feat[];
  ruleData: RuleData;
  confirmSpecies: RaceDefinitionContract | null;
  isOpen: boolean;
  modalKey: string;
  definitionPool: DefinitionPool;
  theme: CharacterTheme;
  upperCase: { singular: string; plural: string; desc: string };
  lowerCase: { singular: string; plural: string; desc: string };
}
export class SpeciesChooseConfirm extends React.PureComponent<Props> {
  handleCancelModal = (): void => {
    const { dispatch, modalKey } = this.props;

    dispatch(modalActions.close(modalKey));
  };

  handleAcceptModal = (): void => {
    const { dispatch, modalKey, confirmSpecies } = this.props;

    if (confirmSpecies) {
      dispatch(characterActions.raceChoose(confirmSpecies));
    }
    dispatch(modalActions.close(modalKey));
  };

  render() {
    const {
      isOpen,
      confirmSpecies,
      species,
      feats,
      ruleData,
      definitionPool,
      theme,
      upperCase,
      lowerCase,
    } = this.props;

    let missingFeatDependencies: Feat[] = [];
    if (species && confirmSpecies) {
      let speciesFeatIds = RaceUtils.getFeatIds(species);
      let confirmSpeciesFeatIds = RaceUtils.getFeatIds(confirmSpecies);
      feats.forEach((feat) => {
        if (
          speciesFeatIds.includes(FeatUtils.getId(feat)) &&
          !confirmSpeciesFeatIds.includes(FeatUtils.getId(feat))
        ) {
          missingFeatDependencies.push(feat);
        }
      });
    }

    const acceptChangesText = species
      ? "Change " + upperCase.singular
      : "Choose " + upperCase.singular;
    const heading = species
      ? "Confirm Change " + upperCase.singular
      : "Confirm " + upperCase.singular;

    return (
      <FullscreenModal
        clsNames={["species-confirm-modal"]}
        onCancel={this.handleCancelModal}
        onAccept={this.handleAcceptModal}
        isOpen={isOpen}
        acceptChangesText={acceptChangesText}
        heading={heading}
      >
        {missingFeatDependencies.length > 0 && (
          <div className="builder-message builder-message-warning">
            By changing your {lowerCase.singular}, you will have invalid feat
            selection(s):
            {" " +
              missingFeatDependencies
                .map((feat) => FeatUtils.getName(feat))
                .join(", ")}
          </div>
        )}
        {confirmSpecies && (
          <SpeciesChooseContent
            species={confirmSpecies}
            ruleData={ruleData}
            definitionPool={definitionPool}
            theme={theme}
            summaryTitle={upperCase.singular}
          />
        )}
      </FullscreenModal>
    );
  }
}

function mapStateToProps(state) {
  const modalKey = "species-choose-confirm";

  return {
    modalKey,
    isOpen: modalSelectors.getOpenStatus(state, modalKey),
    confirmSpecies: builderSelectors.getConfirmSpecies(state),
    species: rulesEngineSelectors.getRace(state),
    feats: rulesEngineSelectors.getFeats(state),
    ruleData: rulesEngineSelectors.getRuleData(state),
    definitionPool: serviceDataSelectors.getDefinitionPool(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

export default connect(mapStateToProps)(SpeciesChooseConfirm);
