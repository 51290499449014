import React from "react";
import { connect, DispatchProp } from "react-redux";

import { TruncatedContent } from "@dndbeyond/character-components/es";
import {
  rulesEngineSelectors,
  characterActions,
  ClassDefinitionContract,
  FormatUtils,
  HelperUtils,
  RuleData,
  RuleDataUtils,
  SourceData,
} from "@dndbeyond/character-rules-engine/es";

import { HtmlContent } from "~/components/HtmlContent";

import { modalActions } from "../../../../Shared/actions";
import { FullscreenModal } from "../../../../Shared/components/common/FullscreenModal";
import {
  Collapsible,
  CollapsibleHeader,
} from "../../../../Shared/components/legacy/common/Collapsible";
import { modalSelectors } from "../../../../Shared/selectors";
import PageHeader from "../../../components/PageHeader";
import { builderSelectors } from "../../../selectors";
import { BuilderAppState } from "../../../typings";

interface ClassFeatureProps {
  requiredLevel: number;
  name: string;
  description: string;
}
class ClassConfirmClassFeature extends React.PureComponent<ClassFeatureProps> {
  renderHeader = (): React.ReactNode => {
    const { requiredLevel, name } = this.props;

    const metaItems: Array<string> = [
      `${FormatUtils.ordinalize(requiredLevel)} level`,
    ];

    return <CollapsibleHeader metaItems={metaItems} heading={name} />;
  };

  render() {
    const { description } = this.props;

    return (
      <Collapsible
        trigger={this.renderHeader()}
        clsNames={["class-manager-feature-name"]}
        initiallyCollapsed={false}
      >
        <TruncatedContent
          className="class-manager-feature-description"
          content={description}
        />
      </Collapsible>
    );
  }
}

interface Props extends DispatchProp {
  ruleData: RuleData;
  confirmClass: ClassDefinitionContract | null;
  isOpen: boolean;
  modalKey: string;
}
interface State {
  level: number;
}
class ClassChooseConfirm extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      level: 1,
    };
  }

  handleCancelModal = (): void => {
    const { dispatch, modalKey } = this.props;

    dispatch(modalActions.close(modalKey));
  };

  handleAcceptModal = (): void => {
    const { level } = this.state;
    const { dispatch, modalKey, confirmClass } = this.props;

    if (confirmClass) {
      dispatch(characterActions.classAddRequest(confirmClass, level));
    }
    dispatch(modalActions.close(modalKey));
  };

  render() {
    const { isOpen, confirmClass, ruleData } = this.props;

    let classFeatures;
    let name: string | null = null;
    let portraitAvatarUrl: string | null = null;
    let description: string | null = null;
    let sourceCalloutDescriptionNode: React.ReactNode;

    if (confirmClass) {
      classFeatures = confirmClass.classFeatures;
      name = confirmClass.name;
      portraitAvatarUrl = confirmClass.portraitAvatarUrl;
      description = confirmClass.description;

      let shouldShowCallout: boolean = true;
      let calloutSourceDataInfo: any = null;
      if (confirmClass && confirmClass.sources) {
        confirmClass.sources.forEach((sourceMapping) => {
          let sourceDataInfo: SourceData | null =
            HelperUtils.lookupDataOrFallback(
              RuleDataUtils.getSourceDataLookup(ruleData),
              sourceMapping.sourceId
            );

          if (
            shouldShowCallout &&
            sourceDataInfo &&
            sourceDataInfo.sourceCategory &&
            sourceDataInfo.sourceCategory.isEnabledByDefault
          ) {
            shouldShowCallout = false;
          }

          if (
            !calloutSourceDataInfo &&
            sourceDataInfo &&
            sourceDataInfo.sourceCategory &&
            !sourceDataInfo.sourceCategory.isEnabledByDefault &&
            sourceDataInfo.sourceCategory.description
          ) {
            calloutSourceDataInfo = sourceDataInfo;
          }
        });
      }

      if (shouldShowCallout && calloutSourceDataInfo !== null) {
        sourceCalloutDescriptionNode = (
          <HtmlContent
            className="confirm-class-source-callout"
            html={calloutSourceDataInfo.sourceCategory.description}
            withoutTooltips
          />
        );
      }
    }

    return (
      <FullscreenModal
        clsNames={["class-confirm-modal"]}
        onCancel={this.handleCancelModal}
        onAccept={this.handleAcceptModal}
        isOpen={isOpen}
        acceptChangesText="Add Class"
        heading="Confirm Add Class"
      >
        {confirmClass !== null && (
          <div className="confirm-class class-detail">
            <div className="class-detail-primary">
              <div className="class-detail-aside">
                <div className="class-detail-preview">
                  {portraitAvatarUrl && (
                    <img
                      className="class-detail-preview-img"
                      src={portraitAvatarUrl}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <PageHeader>{name}</PageHeader>
              {sourceCalloutDescriptionNode}
              {description && (
                <HtmlContent
                  className="class-detail-description"
                  html={description}
                  withoutTooltips
                />
              )}
            </div>
            <div className="class-detail-secondary">
              <div className="class-detail-features">
                {classFeatures.map((feature) => (
                  <ClassConfirmClassFeature {...feature} key={feature.id} />
                ))}
              </div>
            </div>
          </div>
        )}
      </FullscreenModal>
    );
  }
}

function mapStateToProps(state: BuilderAppState) {
  const modalKey = "class-choose-confirm";

  return {
    modalKey,
    isOpen: modalSelectors.getOpenStatus(state, modalKey),
    confirmClass: builderSelectors.getConfirmClass(state),
    ruleData: rulesEngineSelectors.getRuleData(state),
  };
}

export default connect(mapStateToProps)(ClassChooseConfirm);
