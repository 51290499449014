import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  FeatureFlagContext,
  PrivacyTypeRadio,
} from "@dndbeyond/character-components/es";
import {
  AnySimpleDataType,
  characterActions,
  CharacterPreferences,
  CharacterTheme,
  CharacterUtils,
  CharClass,
  ClassSpellListSpellsLookup,
  ClassUtils,
  Constants,
  DataOriginRefData,
  HelperUtils,
  HtmlSelectOption,
  Race,
  RuleData,
  RuleDataUtils,
  rulesEngineSelectors,
  RaceUtils,
} from "@dndbeyond/character-rules-engine/es";
import { Dice } from "@dndbeyond/dice";

import { Header } from "~/subApps/sheet/components/Sidebar/components/Header";

import config from "../../../../config";
import { appEnvActions, sidebarActions } from "../../../actions";
import { appEnvSelectors } from "../../../selectors";
import { SharedAppState } from "../../../stores/typings";
import { PaneComponentEnum, PaneIdentifierUtils } from "../../../utils";
import PreferencesPaneSelectField from "./PreferencesPaneSelectField";
import PreferencesPaneToggleField from "./PreferencesPaneToggleField";
import PreferencesPaneTogglesField, {
  ToggleInfo,
} from "./PreferencesPaneTogglesField";

interface Props extends DispatchProp {
  activeSourceCategories: Array<number>;
  preferences: CharacterPreferences;
  ruleData: RuleData;
  diceEnabled: boolean;
  classes: Array<CharClass>;
  species: Race | null;
  dataOriginRefData: DataOriginRefData;
  classSpellListSpellsLookup: ClassSpellListSpellsLookup;
  characterTheme: CharacterTheme;
}
class PreferencesPane extends React.PureComponent<Props> {
  static defaultProps = {
    diceEnabled: false,
  };

  handlePreferenceChange = (
    prefKey: string,
    value: AnySimpleDataType
  ): void => {
    const { dispatch } = this.props;
    const typedPrefKey = CharacterUtils.getPreferenceKey(prefKey);
    if (typedPrefKey !== null) {
      dispatch(characterActions.preferenceChoose(typedPrefKey, value));
    }
  };

  handleIntPreferenceChange = (prefKey: string, value: number | null): void => {
    const { dispatch } = this.props;
    const typedPrefKey = CharacterUtils.getPreferenceKey(prefKey);
    if (typedPrefKey !== null) {
      dispatch(characterActions.preferenceChoose(typedPrefKey, value));
    }
  };

  handleSourceCategoryChange = (sourceId: number, isActive: boolean): void => {
    const { dispatch, activeSourceCategories } = this.props;
    let newSourceCats: Array<number> = [];
    if (isActive) {
      newSourceCats = [...activeSourceCategories, sourceId];
    } else {
      newSourceCats = activeSourceCategories.filter((id) => id !== sourceId);
    }

    dispatch(characterActions.activeSourceCategoriesSet(newSourceCats));
  };

  handleOptionalClassFeaturesPreferenceChangePromise = (
    newIsEnabled: boolean,
    accept: () => void,
    reject: () => void
  ): void => {
    const { dispatch, classes, classSpellListSpellsLookup } = this.props;
    const spellListIds: Array<number> =
      ClassUtils.getUpdateEnableOptionalClassFeaturesSpellListIdsToRemove(
        classes,
        newIsEnabled
      );

    const hasSpellsToRemove = spellListIds.some((id) =>
      classSpellListSpellsLookup.hasOwnProperty(id)
    );

    if (!hasSpellsToRemove) {
      this.handlePreferenceChange("enableOptionalClassFeatures", newIsEnabled);
      accept();
    } else {
      dispatch(
        sidebarActions.paneHistoryStart(
          PaneComponentEnum.PREFERENCES_OPTIONAL_CLASS_FEATURES_CONFIRM,
          PaneIdentifierUtils.generatePreferenceOptionalClassFeaturesConfirm(
            spellListIds,
            newIsEnabled
          )
        )
      );
      reject();
    }
  };

  handleOptionalOriginsPreferenceChangePromise = (
    newIsEnabled: boolean,
    accept: () => void,
    reject: () => void
  ): void => {
    const { dispatch, species, classSpellListSpellsLookup } = this.props;

    if (!species) {
      this.handlePreferenceChange("enableOptionalOrigins", newIsEnabled);
      accept();

      return;
    }

    const spellListIds: Array<number> =
      RaceUtils.getUpdateEnableOptionalOriginsSpellListIdsToRemove(
        species,
        newIsEnabled
      );

    const hasSpellsToRemove = spellListIds.some((id) =>
      classSpellListSpellsLookup.hasOwnProperty(id)
    );

    if (!hasSpellsToRemove) {
      this.handlePreferenceChange("enableOptionalOrigins", newIsEnabled);
      accept();
    } else {
      dispatch(
        sidebarActions.paneHistoryStart(
          PaneComponentEnum.PREFERENCES_OPTIONAL_ORIGINS_CONFIRM,
          PaneIdentifierUtils.generatePreferenceOptionalOriginsConfirm(
            spellListIds,
            newIsEnabled
          )
        )
      );
      reject();
    }
  };

  handleProgressionPreferenceChangePromise = (
    newId: string,
    oldId: string,
    accept: () => void,
    reject: () => void
  ): void => {
    const { dispatch } = this.props;

    const newIdValue = HelperUtils.parseInputInt(newId);

    if (newIdValue !== null) {
      dispatch(
        sidebarActions.paneHistoryStart(
          PaneComponentEnum.PREFERENCES_PROGRESSION_CONFIRM,
          PaneIdentifierUtils.generatePreferenceProgressionConfirm(newIdValue)
        )
      );
    }

    reject();
  };

  handleHitPointPreferenceChangePromise = (
    newId: string,
    oldId: string,
    accept: () => void,
    reject: () => void
  ): void => {
    const { dispatch } = this.props;

    const newIdValue = HelperUtils.parseInputInt(newId);

    if (newIdValue !== null) {
      dispatch(
        sidebarActions.paneHistoryStart(
          PaneComponentEnum.PREFERENCES_HIT_POINT_CONFIRM,
          PaneIdentifierUtils.generatePreferenceHitPointConfirm(newIdValue)
        )
      );
    }

    reject();
  };

  handleDiceToggle = (): void => {
    const { dispatch, diceEnabled } = this.props;

    const newDiceEnabledSetting: boolean = !diceEnabled;

    try {
      localStorage.setItem("dice-enabled", newDiceEnabledSetting.toString());
      Dice.setEnabled(newDiceEnabledSetting);
    } catch (e) {}

    dispatch(
      appEnvActions.dataSet({
        diceEnabled: newDiceEnabledSetting,
      })
    );
  };

  handleChangePrivacy = (value: number | null): void => {
    const typedPrefKey = CharacterUtils.getPreferenceKey("privacyType");
    if (typedPrefKey !== null) {
      this.props.dispatch(
        characterActions.preferenceChoose(typedPrefKey, value)
      );
    }
  };

  render() {
    const { preferences, activeSourceCategories, ruleData, diceEnabled } =
      this.props;

    const {
      useHomebrewContent,
      encumbranceType,
      hitPointType,
      progressionType,
      abilityScoreDisplayType,
      sharingType,
      privacyType,
      ignoreCoinWeight,
      enforceFeatRules,
      enforceMulticlassRules,
      showScaledSpells,
      enableOptionalClassFeatures,
      enableOptionalOrigins,
      enableDarkMode,
    } = preferences;

    const sourceCategories = RuleDataUtils.getSourceCategories(ruleData);

    const encumbranceOptions: Array<HtmlSelectOption> = [
      {
        label: "Use Encumbrance",
        value: Constants.PreferenceEncumbranceTypeEnum.ENCUMBRANCE,
      },
      {
        label: "No Encumbrance",
        value: Constants.PreferenceEncumbranceTypeEnum.NONE,
      },
      {
        label: "Variant Encumbrance",
        value: Constants.PreferenceEncumbranceTypeEnum.VARIANT,
      },
    ];

    const hpOptions: Array<HtmlSelectOption> = [
      { label: "Fixed", value: Constants.PreferenceHitPointTypeEnum.FIXED },
      { label: "Manual", value: Constants.PreferenceHitPointTypeEnum.MANUAL },
    ];

    const advancementOptions: Array<HtmlSelectOption> = [
      {
        label: "Milestone",
        value: Constants.PreferenceProgressionTypeEnum.MILESTONE,
      },
      { label: "XP", value: Constants.PreferenceProgressionTypeEnum.XP },
    ];

    const abilityDisplayOptions: Array<HtmlSelectOption> = [
      {
        label: "Modifiers Top",
        value: Constants.PreferenceAbilityScoreDisplayTypeEnum.MODIFIERS_TOP,
      },
      {
        label: "Scores Top",
        value: Constants.PreferenceAbilityScoreDisplayTypeEnum.SCORES_TOP,
      },
    ];

    const sharingOptions: Array<HtmlSelectOption> = [
      { label: "Full", value: Constants.PreferenceSharingTypeEnum.FULL },
      { label: "Limited", value: Constants.PreferenceSharingTypeEnum.LIMITED },
      // {label: 'Stat Block', value: Constants.PreferenceSharingTypeEnum.STAT_BLOCK},
    ];

    // This is only used if the RELEASE_GATE_USER_CHARACTER_SETTINGS flag is false
    const privacyOptions: Array<HtmlSelectOption> = [
      { label: "Private", value: Constants.PreferencePrivacyTypeEnum.PRIVATE },
      // { label: 'Campaign Only', value: Constants.PreferencePrivacyTypeEnum.CAMPAIGN_ONLY },
      { label: "Public", value: Constants.PreferencePrivacyTypeEnum.PUBLIC },
    ];

    let sourceToggles: Array<ToggleInfo> = [];
    let partneredSourceToggles: Array<ToggleInfo> = [];

    sourceCategories.forEach((sourceCategory) => {
      if (!sourceCategory.isToggleable) {
        return null;
      }

      const toggle: ToggleInfo = {
        label: `${sourceCategory.name}`,
        initiallyEnabled: activeSourceCategories.includes(sourceCategory.id),
        onChange: this.handleSourceCategoryChange.bind(this, sourceCategory.id),
        sortOrder: sourceCategory.sortOrder,
      };

      if (sourceCategory.isPartneredContent) {
        partneredSourceToggles.push(toggle);
      } else {
        sourceToggles.push(toggle);
      }
    });

    return (
      <FeatureFlagContext.Consumer>
        {({ userCharacterSettingsFlag }) => (
          <div className="ct-preferences-pane">
            <Header>Preferences</Header>

            <PreferencesPaneTogglesField
              heading="Sources"
              description="Allow or restrict sources to be used for this character."
              toggles={[
                {
                  label: "Homebrew",
                  initiallyEnabled: useHomebrewContent,
                  onChange: this.handlePreferenceChange.bind(
                    this,
                    "useHomebrewContent"
                  ),
                  sortOrder: 0,
                },
                ...sourceToggles,
              ]}
            />
            <PreferencesPaneTogglesField
              heading="Partnered Content"
              description="Allow or restrict partnered content to be used for this character. This content should be used only at your DM's discretion."
              toggles={partneredSourceToggles}
            />
            <PreferencesPaneToggleField
              heading="Underdark Mode"
              description="Enables dark mode for this character"
              initiallyEnabled={enableDarkMode}
              onChange={this.handlePreferenceChange.bind(
                this,
                "enableDarkMode"
              )}
            />
            <PreferencesPaneToggleField
              heading="Dice Rolling"
              description="Enables digital dice rolling for this character"
              initiallyEnabled={diceEnabled}
              onChange={this.handleDiceToggle}
            />
            <PreferencesPaneTogglesField
              heading="Optional Features"
              description="Allow or restrict optional features for this character."
              toggles={[
                {
                  label: "Optional Class Features",
                  initiallyEnabled: enableOptionalClassFeatures,
                  onChangePromise:
                    this.handleOptionalClassFeaturesPreferenceChangePromise,
                },
                {
                  label: "Customize Your Origin",
                  initiallyEnabled: enableOptionalOrigins,
                  onChangePromise:
                    this.handleOptionalOriginsPreferenceChangePromise,
                },
              ]}
            />
            <PreferencesPaneSelectField
              heading="Advancement Type"
              description="Story-based character progression / XP-based character progression"
              onChangePromise={this.handleProgressionPreferenceChangePromise}
              initialOptionRemoved={true}
              options={advancementOptions}
              initialValue={progressionType}
              block={true}
            />
            <PreferencesPaneSelectField
              heading="Hit Point Type"
              description="When leveling up, increase hit points by the fixed value for your chosen class or manually enter a rolled value"
              onChangePromise={this.handleHitPointPreferenceChangePromise}
              initialOptionRemoved={true}
              options={hpOptions}
              initialValue={hitPointType}
              block={true}
            />
            <PreferencesPaneTogglesField
              heading="Use Prerequisites"
              description="Allow or restrict choices based on rule prerequisites for the following for this character"
              toggles={[
                {
                  label: "Feats",
                  initiallyEnabled: enforceFeatRules,
                  onChange: this.handlePreferenceChange.bind(
                    this,
                    "enforceFeatRules"
                  ),
                },
                {
                  label: "Multiclass Requirements",
                  initiallyEnabled: enforceMulticlassRules,
                  onChange: this.handlePreferenceChange.bind(
                    this,
                    "enforceMulticlassRules"
                  ),
                },
              ]}
            />
            <PreferencesPaneToggleField
              heading="Show Level-Scaled Spells"
              description="Display and highlight available spells to cast with higher level spell slots"
              initiallyEnabled={showScaledSpells}
              onChange={this.handlePreferenceChange.bind(
                this,
                "showScaledSpells"
              )}
            />
            <PreferencesPaneSelectField
              heading="Encumbrance Type"
              description="Use the standard encumbrance rules / Disable the encumbrance display / Use the more detailed rules for encumbrance"
              onChange={this.handleIntPreferenceChange.bind(
                this,
                "encumbranceType"
              )}
              initialOptionRemoved={true}
              options={encumbranceOptions}
              initialValue={encumbranceType}
              block={true}
            />
            <PreferencesPaneToggleField
              heading="Ignore Coin Weight"
              description="Coins do not count against your total weight carried (50 coins weigh 1 lb.)"
              initiallyEnabled={ignoreCoinWeight}
              onChange={this.handlePreferenceChange.bind(
                this,
                "ignoreCoinWeight"
              )}
            />
            <PreferencesPaneSelectField
              heading="Ability Score/Modifier Display"
              description="Reverse the arrangement of ability modifiers and scores"
              onChange={this.handleIntPreferenceChange.bind(
                this,
                "abilityScoreDisplayType"
              )}
              initialOptionRemoved={true}
              options={abilityDisplayOptions}
              initialValue={abilityScoreDisplayType}
              block={true}
            />
            {(userCharacterSettingsFlag && (
              <PrivacyTypeRadio
                handleChange={(e) =>
                  this.handleChangePrivacy(parseInt(e?.target?.value))
                }
                darkMode={enableDarkMode}
                initialValue={privacyType}
                themeColor={this.props.characterTheme.themeColor}
                compact
              />
            )) || (
              <PreferencesPaneSelectField
                heading="Character Privacy"
                description="Toggle the viewing of your character to 'Private' (only you can access your character), or 'Public' (anyone with the link can view your character)"
                onChange={this.handleIntPreferenceChange.bind(
                  this,
                  "privacyType"
                )}
                initialOptionRemoved={true}
                options={privacyOptions}
                initialValue={privacyType}
                block={true}
              />
            )}
            {/*<PreferencesPaneSelectField*/}
            {/*heading='Character Notes Sharing'*/}
            {/*description="Toggle the display of the Notes section for your character to 'Limited' (only you and your campaign DM can see notes), or 'Full' (anyone that can view your character can see notes)"*/}
            {/*onChange={this.handleIntPreferenceChange.bind(this, 'sharingType')}*/}
            {/*initialOptionRemoved={true}*/}
            {/*options={sharingOptions}*/}
            {/*initialValue={sharingType}*/}
            {/*block={true}*/}
            {/*/>*/}

            <div className="ct-preferences-pane__version">
              <div className="ct-preferences-pane__version-label">Version:</div>
              <div className="ct-preferences-pane__version-value">
                {config.version}
              </div>
            </div>
          </div>
        )}
      </FeatureFlagContext.Consumer>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {
    ruleData: rulesEngineSelectors.getRuleData(state),
    preferences: rulesEngineSelectors.getCharacterPreferences(state),
    activeSourceCategories:
      rulesEngineSelectors.getActiveSourceCategories(state),
    diceEnabled: appEnvSelectors.getDiceEnabled(state),
    classes: rulesEngineSelectors.getClasses(state),
    species: rulesEngineSelectors.getRace(state),
    classSpellListSpellsLookup:
      rulesEngineSelectors.getClassSpellListSpellsLookup(state),
    dataOriginRefData: rulesEngineSelectors.getDataOriginRefData(state),
    characterTheme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

export default connect(mapStateToProps)(PreferencesPane);
