import { useState } from "react";

import {
  AbilityManager,
  HelperUtils,
  RuleData,
  RuleDataUtils,
} from "@dndbeyond/character-rules-engine/es";

import { NumberDisplay } from "~/components/NumberDisplay";
import { useRaceOrSpecies } from "~/hooks/useRaceOrSpecies";

import AbilityIcon from "../Icons/AbilityIcon/AbilityIcon";

interface Props {
  ability: AbilityManager;
  ruleData: RuleData;
  showHeader?: boolean;
  isReadonly: boolean;
  className?: string;
}

export default function AbilityScoreManager({
  ability,
  ruleData,
  isReadonly,
  showHeader = true,
  className = "",
}: Props) {
  const [overrideScore, setOverrideScore] = useState(
    ability.getOverrideScore()
  );
  const [otherBonus, setOtherBonus] = useState(ability.getOtherBonus());
  const { desc } = useRaceOrSpecies();

  const handleOtherBonusBlur = (
    evt: React.FocusEvent<HTMLInputElement>
  ): void => {
    let value: number | null = HelperUtils.parseInputInt(evt.target.value);
    setOtherBonus(ability.handleOtherBonusChange(value));
  };

  const handleOverrideScoreBlur = (
    evt: React.FocusEvent<HTMLInputElement>
  ): void => {
    const value: number | null = HelperUtils.parseInputInt(evt.target.value);
    setOverrideScore(ability.handleOverrideScoreChange(value));
  };

  const handleOtherBonusChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setOtherBonus(HelperUtils.parseInputInt(evt.target.value));
  };

  const handleOverrideScoreChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setOverrideScore(HelperUtils.parseInputInt(evt.target.value));
  };

  const label = ability.getLabel();
  const modifier = ability.getModifier();
  const baseScore = ability.getBaseScore();
  const statId = ability.getId();
  const statKey = ability.getStatKey();
  const totalScore = ability.getTotalScore();
  const speciesBonus = ability.getRacialBonus();
  const classBonuses = ability.getClassBonuses();
  const miscBonus = ability.getMiscBonus();
  const stackingBonus = ability.getStackingBonus();
  const setScore = ability.getSetScore();

  let classNames: Array<string> = [
    className,
    "ddbc-ability-score-manager",
    `ddbc-ability-score-manager--${statKey}`,
  ];

  return (
    <div className={classNames.join(" ")}>
      {showHeader && (
        <div className="ddbc-ability-score-manager__header">
          <AbilityIcon
            statId={statId}
            themeMode="light"
            className="ddbc-ability-score-manager__header-icon"
          />
          <div className="ddbc-ability-score-manager__header-name">{label}</div>
        </div>
      )}
      <div className="ddbc-ability-score-manager__components">
        <div className="ddbc-ability-score-manager__component">
          <div className="ddbc-ability-score-manager__component-label">
            Total Score
          </div>
          <div className="ddbc-ability-score-manager__component-value">
            {totalScore === null ? "--" : totalScore}
          </div>
        </div>
        <div className="ddbc-ability-score-manager__component">
          <div className="ddbc-ability-score-manager__component-label">
            Modifier
          </div>
          <div className="ddbc-ability-score-manager__component-value">
            <NumberDisplay type="signed" number={modifier} />
          </div>
        </div>
        <div className="ddbc-ability-score-manager__component">
          <div className="ddbc-ability-score-manager__component-label">
            Base Score
          </div>
          <div className="ddbc-ability-score-manager__component-value">
            {baseScore === null ? "--" : baseScore}
          </div>
        </div>
        <div className="ddbc-ability-score-manager__component">
          <div className="ddbc-ability-score-manager__component-label">
            {desc} Bonus
          </div>
          <div className="ddbc-ability-score-manager__component-value">
            <NumberDisplay type="signed" number={speciesBonus} />
          </div>
        </div>
        <div className="ddbc-ability-score-manager__component">
          <div className="ddbc-ability-score-manager__component-label">
            Ability Improvements
          </div>
          <div className="ddbc-ability-score-manager__component-value">
            <NumberDisplay type="signed" number={classBonuses} />
          </div>
        </div>
        <div className="ddbc-ability-score-manager__component">
          <div className="ddbc-ability-score-manager__component-label">
            Misc Bonus
          </div>
          <div className="ddbc-ability-score-manager__component-value">
            <NumberDisplay type="signed" number={miscBonus} />
          </div>
        </div>
        <div className="ddbc-ability-score-manager__component">
          <div className="ddbc-ability-score-manager__component-label">
            Stacking Bonus
          </div>
          <div className="ddbc-ability-score-manager__component-value">
            <NumberDisplay type="signed" number={stackingBonus} />
          </div>
        </div>
        <div className="ddbc-ability-score-manager__component">
          <div className="ddbc-ability-score-manager__component-label">
            Set Score
          </div>
          <div className="ddbc-ability-score-manager__component-value">
            {setScore}
          </div>
        </div>
      </div>
      <div className="ddbc-ability-score-manager__overrides">
        <div className="ddbc-ability-score-manager__override">
          <div className="ddbc-ability-score-manager__override-label">
            Other Modifier
          </div>
          <div className="ddbc-ability-score-manager__override-value">
            <input
              type="number"
              value={otherBonus === null ? "" : otherBonus}
              placeholder="--"
              onChange={handleOtherBonusChange}
              onBlur={handleOtherBonusBlur}
              readOnly={isReadonly}
            />
          </div>
        </div>
        <div className="ddbc-ability-score-manager__override">
          <div className="ddbc-ability-score-manager__override-label">
            Override Score
          </div>
          <div className="ddbc-ability-score-manager__override-value">
            <input
              type="number"
              value={overrideScore === null ? "" : overrideScore}
              placeholder="--"
              onChange={handleOverrideScoreChange}
              onBlur={handleOverrideScoreBlur}
              readOnly={isReadonly}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
