import { useFeatureFlags } from "~/contexts/FeatureFlag";

export const useRaceOrSpecies = (isLowerCase?: boolean) => {
  const { raceToSpeciesFlag } = useFeatureFlags();

  const singular = raceToSpeciesFlag ? "Species" : "Race";
  const plural = raceToSpeciesFlag ? "Species" : "Races";
  const desc = raceToSpeciesFlag ? "Species" : "Racial";

  return {
    singular: isLowerCase ? singular.toLowerCase() : singular,
    plural: isLowerCase ? plural.toLowerCase() : plural,
    desc: isLowerCase ? desc.toLowerCase() : desc,
  };
};
