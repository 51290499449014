import * as React from "react";

import Tooltip from "../Tooltip";

interface Props {
  sourceName: string | null;
  sourceFullName: string | null;
  sourcePage: number | null;
  sourceChapter: number | null;
  pageLabel: string;
  chapterLabel: string;
  className: string;
  isDarkMode?: boolean;
}
export default class SourceAbbr extends React.PureComponent<Props> {
  static defaultProps = {
    sourceName: null,
    sourceFullName: null,
    sourcePage: null,
    sourceChapter: null,
    pageLabel: "pg.",
    chapterLabel: "ch.",
    className: "",
    isDarkMode: false,
  };

  render() {
    const {
      sourceName,
      sourceFullName,
      sourcePage,
      sourceChapter,
      pageLabel,
      chapterLabel,
      isDarkMode,
    } = this.props;

    let sourceReferenceText: Array<string> = [];
    if (sourceChapter) {
      sourceReferenceText.push(`${chapterLabel} ${sourceChapter}`);
    }
    if (sourcePage) {
      sourceReferenceText.push(`${pageLabel} ${sourcePage}`);
    }

    return (
      <>
        <span className="ddbc-source-abbr">
          <Tooltip
            title={sourceFullName ? sourceFullName : ""}
            isDarkMode={isDarkMode}
          >
            <span className="ddbc-source-abbr__abbr">{sourceName}</span>
            {sourceReferenceText.length > 0 && (
              <span className="ddbc-source-abbr__ref">
                , {sourceReferenceText.join(", ")}
              </span>
            )}
          </Tooltip>
        </span>
      </>
    );
  }
}
