import * as React from "react";

import { SourceAbbr as CommonSourceAbbr } from "@dndbeyond/character-common-components/es";
import {
  CharacterTheme,
  RuleData,
  RuleDataUtils,
} from "@dndbeyond/character-rules-engine/es";

interface Props {
  sourceId: number;
  sourcePage: number | null;
  sourceChapter: number | null;
  pageLabel?: string;
  chapterLabel?: string;
  ruleData: RuleData;
  theme?: CharacterTheme;
}
export default class SourceAbbr extends React.PureComponent<Props> {
  static defaultProps = {
    sourcePage: null,
    sourceChapter: null,
  };

  render() {
    const {
      sourceId,
      sourcePage,
      sourceChapter,
      pageLabel,
      chapterLabel,
      ruleData,
      theme,
    } = this.props;

    if (sourceId === null) {
      return null;
    }

    let sourceInfo = RuleDataUtils.getSourceDataInfo(sourceId, ruleData);

    return (
      <CommonSourceAbbr
        isDarkMode={theme?.isDarkMode}
        sourceName={sourceInfo ? sourceInfo.name : null}
        sourceFullName={sourceInfo ? sourceInfo.description : null}
        sourcePage={sourcePage}
        sourceChapter={sourceChapter}
        pageLabel={pageLabel}
        chapterLabel={chapterLabel}
      />
    );
  }
}
