import React from "react";

import { CharacterTheme } from "@dndbeyond/character-rules-engine";
import Pen from "@dndbeyond/fontawesome-cache/svgs/light/pen.svg";

import styles from "./styles.module.css";

interface Props {
  onClick: () => void;
  theme: CharacterTheme;
  children: React.ReactNode;
}

export const EditableName = ({ children, onClick, theme }: Props) => (
  <div className={styles.editableName}>
    <div>{children}</div>
    <button className={styles.button} onClick={onClick}>
      <Pen className={styles.icon} style={{ fill: theme.themeColor }} />
    </button>
  </div>
);
