import { useState } from "react";

import { AbilityIcon } from "@dndbeyond/character-components/es";
import {
  AbilityManager,
  FormatUtils,
  HelperUtils,
} from "@dndbeyond/character-rules-engine/es";

import { useRaceOrSpecies } from "~/hooks/useRaceOrSpecies";

interface Props {
  ability: AbilityManager;
}

export default function AbilityScoreCalculations({ ability }: Props) {
  const [overrideScore, setOverrideScore] = useState(
    ability.getOverrideScore()
  );
  const [otherBonus, setOtherBonus] = useState(ability.getOtherBonus());
  const { desc } = useRaceOrSpecies();

  const handleOtherBonusBlur = (
    evt: React.FocusEvent<HTMLInputElement>
  ): void => {
    const value = HelperUtils.parseInputInt(evt.target.value);
    setOtherBonus(ability.handleOtherBonusChange(value));
  };
  const handleOtherBonusChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setOtherBonus(HelperUtils.parseInputInt(evt.target.value));
  };
  const handleOverrideScoreBlur = (
    evt: React.FocusEvent<HTMLInputElement>
  ): void => {
    const value = HelperUtils.parseInputInt(evt.target.value);
    setOverrideScore(ability.handleOverrideScoreChange(value));
  };
  const handleOverrideScoreChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setOverrideScore(HelperUtils.parseInputInt(evt.target.value));
  };

  const totalScore = ability.getTotalScore();
  const abilityModifier = ability.getModifier();
  const speciesBonus = ability.getRacialBonus();
  const classBonuses = ability.getClassBonuses();
  const miscBonus = ability.getMiscBonus();

  return (
    <div
      className={`ability-score-calc ability-score-calc-${ability.getStatKey()}`}
    >
      <div className="ability-score-calc-header">
        <AbilityIcon
          statId={ability.getId()}
          themeMode="light"
          className="ability-score-calc-icon"
        />
        <div className="ability-score-calc-name">{ability.getLabel()}</div>
      </div>
      <div className="ability-score-calc-components">
        <div className="ability-score-calc-component">
          <div className="ability-score-calc-component-label">Total Score</div>
          <div className="ability-score-calc-component-value">
            {totalScore === null ? "--" : totalScore}
          </div>
        </div>
        <div className="ability-score-calc-component">
          <div className="ability-score-calc-component-label">Modifier</div>
          <div className="ability-score-calc-component-value">
            {abilityModifier === null
              ? "--"
              : FormatUtils.renderSignedNumber(abilityModifier)}
          </div>
        </div>
        <div className="ability-score-calc-component">
          <div className="ability-score-calc-component-label">Base Score</div>
          <div className="ability-score-calc-component-value">
            {ability.getBaseScore() === null ? "--" : ability.getBaseScore()}
          </div>
        </div>
        <div className="ability-score-calc-component">
          <div className="ability-score-calc-component-label">{desc} Bonus</div>
          <div className="ability-score-calc-component-value">
            {FormatUtils.renderSignedNumber(speciesBonus)}
          </div>
        </div>
        <div className="ability-score-calc-component">
          <div className="ability-score-calc-component-label">
            Ability Improvements
          </div>
          <div className="ability-score-calc-component-value">
            {FormatUtils.renderSignedNumber(classBonuses)}
          </div>
        </div>
        <div className="ability-score-calc-component">
          <div className="ability-score-calc-component-label">Misc Bonus</div>
          <div className="ability-score-calc-component-value">
            {FormatUtils.renderSignedNumber(miscBonus)}
          </div>
        </div>
        <div className="ability-score-calc-component">
          <div className="ability-score-calc-component-label">Set Score</div>
          <div className="ability-score-calc-component-value">
            {ability.getSetScore()}
          </div>
        </div>
      </div>
      <div className="ability-score-calc-overrides">
        <div className="ability-score-calc-override">
          <div className="ability-score-calc-override-label">
            Other Modifier
          </div>
          <div className="ability-score-calc-override-value">
            <input
              type="number"
              value={otherBonus === null ? "" : otherBonus}
              placeholder="--"
              onChange={handleOtherBonusChange}
              onBlur={handleOtherBonusBlur}
            />
          </div>
        </div>
        <div className="ability-score-calc-override">
          <div className="ability-score-calc-override-label">
            Override Score
          </div>
          <div className="ability-score-calc-override-value">
            <input
              type="number"
              value={overrideScore === null ? "" : overrideScore}
              placeholder="--"
              onChange={handleOverrideScoreChange}
              onBlur={handleOverrideScoreBlur}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
