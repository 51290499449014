import * as React from "react";

import { Tooltip } from "@dndbeyond/character-common-components/es";

import { DarkAttunementSvg, GrayAttunementSvg } from "../../Svg";

interface SvgInjectedProps {
  className?: string;
}
interface Props {
  className?: string;
  showTooltip?: boolean;
  isDarkMode?: boolean;
}
const AttunementIcon: React.FunctionComponent<Props> = ({
  className = "",
  showTooltip = true,
  isDarkMode,
}) => {
  let classNames: Array<string> = [className, "ddbc-attunement-icon"];

  let SvgIcon: React.ComponentType<SvgInjectedProps> = isDarkMode
    ? GrayAttunementSvg
    : DarkAttunementSvg;

  return (
    <span className={classNames.join(" ")}>
      <Tooltip
        title={"Item is Attuned"}
        enabled={showTooltip}
        isDarkMode={isDarkMode}
      >
        <SvgIcon className="ddbc-attunement-icon__icon" />
      </Tooltip>
    </span>
  );
};

export default AttunementIcon;
