import clsx from "clsx";
import {
  ChangeEvent,
  FC,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CharacterAvatarPortrait,
  CharacterName,
  CharacterProgressionSummary,
  CharacterSummary,
  LightPencilSvg,
  ThemedPaintBrushSvg,
} from "@dndbeyond/character-components/es";

import { Button } from "~/components/Button/Button";
import { DeathCauseEnum } from "~/constants";
import { useCharacterTheme } from "~/contexts/characterTheme";
import { useCharacterEngine } from "~/hooks/useCharacterEngine";
import useUserId from "~/hooks/useUserId";
import { sidebarActions } from "~/tools/js/Shared/actions";
import { appEnvSelectors } from "~/tools/js/Shared/selectors";
import { CharacterStatusSlug } from "~/types";

import { PaneComponentEnum } from "../../../types";
import styles from "./styles.module.css";

export interface OverviewProps extends HTMLAttributes<HTMLDivElement> {}

export const Overview: FC<OverviewProps> = ({ className, ...props }) => {
  const {
    ruleData,
    characterName,
    characterGender: gender,
    race: species,
    experienceInfo: xpInfo,
    deathCause,
    preferences,
    decorationInfo,
    playerName,
    playerId,
    characterActions,
    decorationUtils,
    classUtils,
    classes,
    characterStatusSlug: characterStatus,
  } = useCharacterEngine();
  const dispatch = useDispatch();

  const { isDarkMode } = useCharacterTheme();

  const userId = useUserId();
  const isReadonly = useSelector(appEnvSelectors.getIsReadonly);

  const isUserCharacter = playerId === Number(userId);
  const isPremade = characterStatus === CharacterStatusSlug.PREMADE;

  const [isNameEditorVisible, setIsNameEditorVisible] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isNameEditorVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isNameEditorVisible]);

  const handleNameClick = (): void => {
    setIsNameEditorVisible(true);
  };

  const handleNameChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = evt.target.value;

    if (inputValue !== characterName && inputValue !== "") {
      dispatch(characterActions.nameSet(inputValue));
    }
    setIsNameEditorVisible(false);
  };

  const handleDecorateMenuClick = (): void => {
    dispatch(sidebarActions.paneHistoryPush(PaneComponentEnum.DECORATE));
  };

  return (
    <div
      className={clsx([styles.intro, isDarkMode && styles.dark, className])}
      {...props}
    >
      <div className={styles.summary}>
        <CharacterAvatarPortrait
          className={styles.avatar}
          avatarUrl={decorationUtils.getAvatarInfo(decorationInfo).avatarUrl}
        />
        {
          <div
            className={styles.characterName}
            onClick={isReadonly ? undefined : handleNameClick}
          >
            {isNameEditorVisible && !isReadonly ? (
              <input
                ref={inputRef}
                type="text"
                defaultValue={characterName ?? ""}
                onBlur={handleNameChange}
              />
            ) : (
              <>
                <CharacterName
                  name={characterName ?? ""}
                  isDead={deathCause !== DeathCauseEnum.NONE}
                  isFaceMenu={true}
                />
                {!isReadonly && <LightPencilSvg />}
              </>
            )}
          </div>
        }
        {/* DISPLAY PLAYER NAME IF ITS NOT A PREMADE OR YOUR CHARACTER */}
        {!isUserCharacter && !isPremade && (
          <div>
            <span className={styles.playerNameLabel}>Player: </span>
            {playerName}
          </div>
        )}
        {/* SHOW SPECIES, GENDER AND XP/LEVEL INFO */}
        <div className={styles.description}>
          <CharacterSummary
            className={styles.summaryText}
            classes={null}
            species={species}
            gender={gender}
          />
          <CharacterProgressionSummary
            ruleData={ruleData}
            progressionType={preferences.progressionType}
            xpInfo={xpInfo}
          />
        </div>
      </div>
      {/* DISPLAY CLASS LIST */}
      <div className={styles.classList}>
        {classes.map((charClass) => (
          <div className={styles.classItem} key={classUtils.getId(charClass)}>
            <div
              className={styles.classImage}
              style={{
                backgroundImage: `url(${classUtils.getPortraitUrl(charClass)})`,
              }}
            >
              <div className={styles.classLevel}>
                {classUtils.getLevel(charClass)}
              </div>
            </div>
            <div className={styles.classSummary}>
              <div>{classUtils.getName(charClass)}</div>
              <div className={styles.classMeta}>
                {classUtils.getSubclassName(charClass) ?? "No Subclass"}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* DISPLAY DECORATE BUTTON AS A "FEATURE CALLOUT" */}
      {!isReadonly && (
        <div className={styles.featureCallout}>
          <Button
            size="x-small"
            variant="outline"
            className={styles.decorateButton}
            forceThemeMode="dark"
            themed
            onClick={handleDecorateMenuClick}
          >
            <ThemedPaintBrushSvg
              theme={decorationUtils.getCharacterTheme(decorationInfo)}
            />
            <span>Change Sheet Appearance</span>
          </Button>
        </div>
      )}
    </div>
  );
};
