import clsx from "clsx";
import { FC, HTMLAttributes } from "react";

import ChevronLeft from "@dndbeyond/fontawesome-cache/svgs/solid/chevron-left.svg";
import ChevronRight from "@dndbeyond/fontawesome-cache/svgs/solid/chevron-right.svg";

import { Button } from "~/components/Button";
import { useCharacterTheme } from "~/contexts/characterTheme";
import { SidebarPaneState } from "~/tools/js/Shared/stores/typings";

import styles from "./styles.module.css";

interface PaneControlsProps extends HTMLAttributes<HTMLElement> {
  pane?: SidebarPaneState;
  handlePrevious?: () => void;
  handleNext?: () => void;
}

export const PaneControls: FC<PaneControlsProps> = ({
  pane,
  handlePrevious,
  handleNext,
  ...props
}) => {
  const { isDarkMode } = useCharacterTheme();

  if (pane === undefined) return null;

  const { historyIdx, history } = pane;

  if (history.length <= 1) return null;

  const isAtStart = historyIdx === 0;
  const isAtEnd = historyIdx === history.length - 1;
  const forceDarkMode =
    typeof historyIdx === "number" &&
    !isDarkMode &&
    history[historyIdx].componentType === "CHARACTER_MANAGE";

  return (
    <nav className={styles.controls} {...props}>
      <Button
        className={clsx([styles.button, forceDarkMode && styles.dark])}
        size="xx-small"
        variant="text"
        disabled={isAtStart}
        onClick={isAtStart ? undefined : handlePrevious}
      >
        <ChevronLeft />
        <span className={styles.label}>Prev</span>
      </Button>
      <Button
        className={clsx([styles.button, forceDarkMode && styles.dark])}
        size="xx-small"
        variant="text"
        disabled={isAtEnd}
        onClick={isAtEnd ? undefined : handleNext}
      >
        <span className={styles.label}>Next</span>
        <ChevronRight />
      </Button>
    </nav>
  );
};
