import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ChangeEvent } from "react";

import {
  CharacterPreferences,
  CharacterUtils,
  Constants,
  characterActions,
} from "@dndbeyond/character-rules-engine";
import { Palette } from "@dndbeyond/ftui-components/styles/types";

interface PrivacyTypeRadioProps {
  color?: "primary" | "secondary" | "success" | "error" | "warning" | "info";
  themeColor?: string;
  compact?: boolean;
  darkMode?: boolean;
  handleChange?: (e, value) => void;
  initialValue?: number | null;
  sx?: SxProps<Theme>;
}

const privacyOptions = [
  {
    value: Constants.PreferencePrivacyTypeEnum.CAMPAIGN_ONLY,
    label: "Campaign Only",
    description:
      "Only others within a Campaign you have joined can view your Characters.",
  },
  {
    value: Constants.PreferencePrivacyTypeEnum.PUBLIC,
    label: "Public",
    description: "Anyone with a shared link can view your Characters.",
  },
  {
    value: Constants.PreferencePrivacyTypeEnum.PRIVATE,
    label: "Private",
    description: "Only you can view your Characters.",
  },
];

const PrivacyTypeRadio = ({
  color,
  compact,
  darkMode,
  initialValue,
  handleChange,
  sx,
  themeColor,
}: PrivacyTypeRadioProps) => {
  // Here for reference in next ticket. Delete afterward.
  // const handleChange = (value: number | null): void => {
  //     const typedPrefKey = CharacterUtils.getPreferenceKey('privacyType');
  //     if (typedPrefKey !== null) {
  //         dispatch(characterActions.preferenceChoose(typedPrefKey, value));
  //     }
  // };

  return (
    <FormControl sx={sx}>
      <FormLabel
        id="character-privacy-radio"
        className={
          compact
            ? "ct-sidebar__subheading ct-preferences-pane__field-heading"
            : undefined
        }
        style={{
          color: darkMode ? "#b0b7bd" : "#000",
          fontSize: compact ? 14 : 16,
          fontWeight: 700,
          letterSpacing: -0.5,
          textTransform: "uppercase",
          margin: compact ? "16px 0 0" : "19px 0 0",
          padding: "10px 0",
        }}
      >
        Character Privacy
      </FormLabel>
      <RadioGroup
        aria-labelledby="character-privacy-radio"
        name="controlled-radio-buttons-group"
        value={initialValue}
        onChange={handleChange}
        style={{ marginLeft: 8 }}
      >
        {privacyOptions.map((option, i) => (
          <FormControlLabel
            value={option.value}
            control={
              <Radio
                color={color}
                size={compact ? "small" : "medium"}
                style={{
                  paddingTop: 0,
                  color: initialValue === option.value ? themeColor : "inherit",
                }}
              />
            }
            label={
              <>
                <Typography fontSize={compact ? 13 : 16} lineHeight={1.5}>
                  {option.label}
                </Typography>
                <Typography fontSize={12} lineHeight={1.5} color="grey.500">
                  {option.description}
                </Typography>
              </>
            }
            style={{
              marginTop: 5,
              marginBottom: 5,
              display: "flex",
              alignItems: "flex-start",
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default PrivacyTypeRadio;
