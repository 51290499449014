import * as React from "react";

import { TruncatedContent as CommonTruncatedContent } from "@dndbeyond/character-common-components/es";
import { FormatUtils } from "@dndbeyond/character-rules-engine/es";

interface Props {
  content: string;
  showLabelOverride?: string;
  hideLabelOverride?: string;
  clsNames: Array<string>;
  className: string;
  maxDescriptionLength?: number;
}
export default class TruncatedContent extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
    clsNames: [],
  };

  render() {
    const { className, content, ...restProps } = this.props;

    return (
      <CommonTruncatedContent
        {...restProps}
        className={className}
        content={FormatUtils.stripTooltipTags(content)}
      />
    );
  }
}
