import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ApiAdapterPromise,
  ApiAdapterRequestConfig,
  ApiResponse,
  rulesEngineSelectors,
  Race,
  RuleData,
  RaceDefinitionContract,
} from "@dndbeyond/character-rules-engine/es";

import { useFeatureFlags } from "~/contexts/FeatureFlag";
import { useRaceOrSpecies } from "~/hooks/useRaceOrSpecies";

import { modalActions } from "../../../../Shared/actions";
import { apiCreatorSelectors } from "../../../../Shared/selectors";
import { builderActions } from "../../../actions";
import Page from "../../../components/Page";
import { PageBody } from "../../../components/PageBody";
import PageHeader from "../../../components/PageHeader";
import SpeciesChooser from "../../../components/SpeciesChooser";
import { SpeciesDisplaySimple } from "../../../components/SpeciesDisplaySimple";
import { navigationConfig } from "../../../config";
import { RouteKey } from "../../../constants/navigation";
import { BuilderAppState } from "../../../typings";
import ConnectedBuilderPage from "../ConnectedBuilderPage";

interface Props {
  loadSpecies: (
    additionalConfig?: Partial<ApiAdapterRequestConfig>
  ) => ApiAdapterPromise<ApiResponse<Array<RaceDefinitionContract>>>;
  species: Race | null;
  ruleData: RuleData;
  characterId: number;
}
const SpeciesChoose: React.FC<Props> = ({
  loadSpecies,
  species,
  ruleData,
  characterId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { singular: singularUpperCase } = useRaceOrSpecies();
  const { osirisCharacterAppFeFlag } = useFeatureFlags();

  let headerText = species ? "Change" : "Choose a";
  if (osirisCharacterAppFeFlag) {
    headerText = species ? "Change Origin:" : "Choose Origin:";
  }

  return (
    <Page>
      <PageBody>
        <PageHeader>{`${headerText} ${singularUpperCase}`}</PageHeader>
        {species && (
          <SpeciesDisplaySimple
            species={species}
            onRequestAction={() =>
              navigate(
                navigationConfig
                  .getRouteDefPath(RouteKey.RACE_MANAGE)
                  .replace(":characterId", characterId)
              )
            }
            actionText={"Keep " + singularUpperCase}
            headingText={"Current " + singularUpperCase}
            ruleData={ruleData}
          />
        )}
        {species && <PageHeader>Select New {singularUpperCase}</PageHeader>}
        <SpeciesChooser
          loadSpecies={loadSpecies}
          onSpeciesSelected={(species) => {
            dispatch(builderActions.confirmSpeciesSet(species));
            dispatch(modalActions.open("species-choose-confirm"));
            window.scrollTo(0, 0);
          }}
          currentSpecies={species}
          ruleData={ruleData}
        />
      </PageBody>
    </Page>
  );
};

export default ConnectedBuilderPage(
  SpeciesChoose,
  RouteKey.RACE_CHOOSE,
  (state: BuilderAppState) => {
    return {
      loadSpecies: apiCreatorSelectors.makeLoadAvailableRaces(state),
      species: rulesEngineSelectors.getRace(state),
      ruleData: rulesEngineSelectors.getRuleData(state),
      characterId: rulesEngineSelectors.getId(state),
    };
  }
);
