import clsx from "clsx";
import { ChangeEvent, FC, HTMLAttributes } from "react";

import styles from "./styles.module.css";

export interface SearchProps extends HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Search: FC<SearchProps> = ({
  className,
  placeholder = "Search...",
  value,
  onChange,
  ...props
}) => (
  <div className={clsx([styles.container, className])} {...props}>
    <input
      type="search"
      className={styles.search}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      spellCheck={false}
      autoComplete={"off"}
    />
  </div>
);
{
  /* {showAddAmountControls ? this.renderAmountControls() : null} */
}
