import { FC, HTMLAttributes } from "react";

import { useFeatureFlags } from "~/contexts/FeatureFlag";
import { useCharacterTheme } from "~/contexts/characterTheme";
import { SidebarPaneComponentInfoState } from "~/tools/js/Shared/stores/typings";

import { getActiveEntryComponent } from "../../helpers/getActiveEntryComponent";
import styles from "./styles.module.css";

interface PaneContentProps extends HTMLAttributes<HTMLElement> {
  activePane: SidebarPaneComponentInfoState | null;
}

export const PaneContent: FC<PaneContentProps> = ({ activePane, ...props }) => {
  const { isDarkMode } = useCharacterTheme();
  const { campaignSettingsFlag } = useFeatureFlags();
  const Component = getActiveEntryComponent(
    campaignSettingsFlag,
    activePane?.componentType
  );

  // Empty pane
  if (!activePane)
    return (
      <div className={styles.default} {...props}>
        Select elements on the character sheet to display more information about
      </div>
    );

  // Component not found
  if (!Component) return <div {...props}>Component was not found.</div>;

  // Return correct component
  return (
    <div {...props}>
      <Component
        {...activePane.componentProps}
        theme={isDarkMode ? "DARK" : "LIGHT"}
        identifiers={activePane.componentIdentifiers}
      />
    </div>
  );
};
