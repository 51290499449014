import { groupBy, keyBy } from "lodash";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { orderBy } from "~/helpers/sortUtils";
import { useCharacterEngine } from "~/hooks/useCharacterEngine";
import { useSource } from "~/hooks/useSource";
import { apiCreatorSelectors } from "~/tools/js/Shared/selectors";
import {
  HtmlSelectOption,
  RaceDefinitionContract as RaceDef,
  RaceDefinitionContract,
} from "~/types";

import { GroupedListingItem, ListingItem } from "../../types";

export interface SpeciesContextType {
  closeModal: () => void;
  defaultSpeciesImageUrl: string;
  isLegacyShowing: boolean;
  isModalShowing: boolean;
  query: string;
  selectedSpecies?: RaceDef;
  setQuery: (query: string) => void;
  source: string;
  sourceOptions: HtmlSelectOption[];
  filteredSpecies: RaceDef[];
  allSpecies: RaceDef[];
  toggleLegacyContent: () => void;
  transformSpecies: (data: RaceDef[]) => ListingItem[];
  setSource: (source: string) => void;
  isLoading: boolean;
  getSpeciesInGroups: (
    species: ListingItem[]
  ) => (ListingItem | GroupedListingItem)[];
}

export const SpeciesContext = createContext<SpeciesContextType>(null!);

export const SpeciesProvider: FC = ({ children }) => {
  const {
    apiAdapterUtils: { getResponseData },
    raceUtils: { getSubRaceShortName, getBaseName, getIsHomebrew },
    ruleData,
    ruleDataUtils: { getSources, getDefaultRaceImageUrl, getRaceGroups },
  } = useCharacterEngine();
  const { getSourceDescription } = useSource();

  const [allSpecies, setAllSpecies] = useState<Array<RaceDef>>([]);
  const [isLegacyShowing, setIsLegacyShowing] = useState(true);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedSpecies, setSelectedSpecies] = useState<RaceDef>();
  const [source, setSource] = useState("");
  const [sourceOptions, setSourceOptions] = useState<HtmlSelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadSpecies = useSelector(apiCreatorSelectors.makeLoadAvailableRaces);

  const defaultSpeciesImageUrl = getDefaultRaceImageUrl(ruleData) ?? "";

  const handleFilteredSpecies = () => {
    let filtered = allSpecies;
    //Check legacy toggle
    if (!isLegacyShowing) {
      filtered = filtered.filter((s) => !s.isLegacy);
    }

    // Check sources
    if (source !== "") {
      if (source === "HOMEBREW") {
        filtered = filtered.filter((s) => s.isHomebrew);
      } else {
        filtered = filtered.filter((s) =>
          s.sources?.some(({ sourceId }) => source === sourceId.toString())
        );
      }
    }
    // Check querys
    if (query !== "") {
      filtered = filtered.filter((s) => {
        return s.fullName?.toLowerCase().includes(query.toLowerCase());
      });
    }
    return filtered;
  };
  // Handle searching through items
  const filteredSpecies = handleFilteredSpecies();

  const onSelectSpecies = (id: string) => {
    const entity = allSpecies.find(
      (s) => `${s.entityRaceId}-${s.entityRaceTypeId}` === id
    );
    if (entity) {
      setSelectedSpecies(entity);
      setIsModalShowing(true);
      window.scrollTo(0, 0);
    }
  };

  const closeModal = () => setIsModalShowing(false);

  const toggleLegacyContent = () => {
    setIsLegacyShowing(!isLegacyShowing);
  };

  //Transform Species data into ListingItems
  const transformSpecies = (data: RaceDef[]): ListingItem[] => {
    return data.map((species) => {
      const shortName = getSubRaceShortName(species);
      const baseName = getBaseName(species);
      const heading = shortName ? `${shortName} ${baseName}` : baseName || "";
      const text = getIsHomebrew(species)
        ? "Homebrew"
        : getSourceDescription(species?.sources?.[0]?.sourceId || 0) || "";

      return {
        type: "species",
        id: `${species.entityRaceId}-${species.entityRaceTypeId}`,
        entityTypeId: species.entityRaceTypeId,
        heading,
        text,
        image: species.portraitAvatarUrl || defaultSpeciesImageUrl,
        entity: species,
        isLegacy: species.isLegacy,
        onClick: onSelectSpecies,
      };
    });
  };

  // get the ListingItems for species, both grouped and individual
  const getSpeciesInGroups = (
    species: ListingItem[]
  ): (ListingItem | GroupedListingItem)[] => {
    let groupedSpecies: ListingItem[] = [];
    let unGroupedSpecies: ListingItem[] = [];

    //species that have a groupId go into the groupedSpecies array and species that don't have a groupId go into the unGroupedSpecies array
    species.forEach((s) => {
      const groupIds = (s.entity as RaceDefinitionContract)?.groupIds;
      if (groupIds && groupIds.length > 0) {
        groupIds.forEach((groupId) => {
          groupedSpecies.push({
            ...s,
            groupId,
          });
        });
      } else {
        unGroupedSpecies.push(s);
      }
    });

    //Create a lookup of grouped species by groupId
    const groupedSpeciesLookup = groupBy(groupedSpecies, "groupId");
    //Create a lookup from all Species Groups from rules data
    const groupDataLookup = keyBy(getRaceGroups(ruleData), "id");
    //This array will hold the final list of grouped species
    const groups: GroupedListingItem[] = [];

    //Iterate through the grouped species lookup to create final groupings
    Object.keys(groupedSpeciesLookup).forEach((groupId) => {
      const groupItems = groupedSpeciesLookup[groupId];
      //If the group only has one item, it is not a group - put it in the unGroupedSpecies array, otherwise create the Grouped Listing Item for the group
      if (groupItems.length === 1) {
        unGroupedSpecies.push(groupItems[0]);
      } else {
        const groupInfo = groupDataLookup[parseInt(groupId)];
        groups.push({
          id: groupId,
          type: "group",
          image: groupInfo.avatarUrl ?? "",
          heading: groupInfo.name ?? "",
          groupId: parseInt(groupId),
          listItems: groupItems,
          onClick: undefined,
        });
      }
    });

    return [...groups, ...unGroupedSpecies];
  };

  const getSpecies = async () => {
    const species = getResponseData(await loadSpecies()) || [];

    setAllSpecies(species);
    if (species.length > 0) {
      setIsLoading(false);
    }
  };

  // Set the source options when the species are loaded
  useEffect(() => {
    // Get all source options in the list of species
    const itemSourceIds = allSpecies.reduce((acc: number[], { sources }) => {
      if (sources)
        sources.forEach(({ sourceId }) => {
          if (!acc.includes(sourceId)) acc.push(sourceId);
        });
      return acc;
    }, []);
    // Get source data and filter out sources that are
    // not in the list of species
    const sourceOptions = orderBy(
      getSources(ruleData)
        .filter((source) => itemSourceIds.includes(source.id))
        .map((source) => ({
          value: "" + source.id,
          label: source.description,
        })),
      "label"
    );
    // Create the species filter options
    let filterOptions: HtmlSelectOption[] = [];
    // Create an option for homebrew species
    if (allSpecies.find((species) => species.isHomebrew)) {
      filterOptions.push({
        label: "Homebrew",
        value: "HOMEBREW",
      });
    }

    // setHasLegacyOptions(allSpecies.some((species) => "isLegacy" in species));

    if (sourceOptions.length) filterOptions.push(...sourceOptions);

    setSourceOptions(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSpecies]);

  useEffect(() => {
    getSpecies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSpecies]);

  return (
    <SpeciesContext.Provider
      value={{
        closeModal,
        defaultSpeciesImageUrl,
        isLegacyShowing,
        isModalShowing,
        query,
        selectedSpecies,
        setQuery,
        sourceOptions,
        filteredSpecies,
        allSpecies,
        toggleLegacyContent,
        transformSpecies,
        source,
        setSource,
        isLoading,
        getSpeciesInGroups,
      }}
    >
      {children}
    </SpeciesContext.Provider>
  );
};

export const useSpeciesContext = () => {
  return useContext(SpeciesContext);
};
