import clsx from "clsx";
import { FC, HTMLAttributes } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import GridIcon from "@dndbeyond/fontawesome-cache/svgs/solid/grid.svg";
import { useIsVisible } from "@dndbeyond/ttui/hooks/useIsVisible";

import { useUnpropagatedClick } from "~/hooks/useUnpropagatedClick";
import { LeftArrowsIcon, RightArrowsIcon } from "~/svgs";
import { sidebarActions } from "~/tools/js/Shared/actions";
import { sidebarSelectors } from "~/tools/js/Shared/selectors";

import { sidebarId } from "../../constants";
import { SectionMenu } from "./SectionMenu";
import styles from "./styles.module.css";

export interface MobileNavProps extends HTMLAttributes<HTMLDivElement> {}

export const MobileNav: FC<MobileNavProps> = ({ className, ...props }) => {
  const {
    ref,
    isVisible: isMenuVisible,
    setIsVisible: setIsMenuVisible,
  } = useIsVisible(false);
  const dispatch = useDispatch();
  const isSidebarVisible = useSelector(sidebarSelectors.getIsVisible);

  const handleToggleSidebar = useUnpropagatedClick(() => {
    setIsMenuVisible(false);
    dispatch(sidebarActions.visibleSet(!isSidebarVisible));
  });

  const handleToggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleCloseMenu = (e: MouseEvent) => {
    e?.stopPropagation();
    setIsMenuVisible(false);
  };

  return createPortal(
    <div className={clsx([styles.mobileNav, className])} ref={ref} {...props}>
      {!isSidebarVisible && (
        <div>
          <button
            className={styles.navToggle}
            onClick={handleToggleMenu}
            aria-label="Show navigation"
          >
            <GridIcon className={styles.icon} />
          </button>
          <SectionMenu open={isMenuVisible} onClose={handleCloseMenu} />
        </div>
      )}
      <button
        className={styles.sidebarToggle}
        onClick={handleToggleSidebar}
        aria-controls={sidebarId}
        aria-label={`${isSidebarVisible ? "Hide" : "Show"} sidebar`}
      >
        {isSidebarVisible ? (
          <RightArrowsIcon className={styles.icon} />
        ) : (
          <LeftArrowsIcon className={styles.icon} />
        )}
      </button>
    </div>,
    document.body
  );
};
