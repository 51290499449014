import { keyBy } from "lodash";
import React from "react";

import { Snippet, SourceAbbr } from "@dndbeyond/character-components/es";
import {
  AbilityLookup,
  BaseSpell,
  BaseFeat,
  Choice,
  ChoiceUtils,
  ClassDefinitionContract,
  Constants,
  DataOriginBaseAction,
  DataOriginRefData,
  InfusionChoice,
  LevelScaleContract,
  Option,
  OptionUtils,
  RuleData,
  RuleDataUtils,
  SnippetData,
  CharacterTheme,
} from "@dndbeyond/character-rules-engine/es";

import FeatureSnippetActions from "./FeatureSnippetActions";
import FeatureSnippetChoices from "./FeatureSnippetChoices";
import FeatureSnippetInfusionChoices from "./FeatureSnippetInfusionChoices";
import FeatureSnippetOption from "./FeatureSnippetOption";
import FeatureSnippetSpells from "./FeatureSnippetSpells";
import styles from "./styles.module.css";

interface Props {
  heading: React.ReactNode;
  dataOriginExtra?: string;
  extraMeta: Array<string>;
  className: string;
  snippetData: SnippetData;
  ruleData: RuleData;
  abilityLookup: AbilityLookup;
  sourceId: number | null;
  sourcePage: number | null;

  spells: Array<BaseSpell>;
  feats: Array<BaseFeat>;
  actions: Array<DataOriginBaseAction>;
  options: Array<Option>;
  choices: Array<Choice>;
  infusionChoices: Array<InfusionChoice>;
  dataOriginRefData: DataOriginRefData;

  levelScale?: LevelScaleContract | null;
  classLevel?: number;
  subclass?: ClassDefinitionContract | null;

  onActionClick?: (action: DataOriginBaseAction) => void;
  onActionUseSet?: (action: DataOriginBaseAction, uses: number) => void;
  onSpellClick?: (spell: BaseSpell) => void;
  onSpellUseSet?: (spell: BaseSpell, uses: number) => void;
  onFeatureClick: () => void;
  onInfusionChoiceClick?: (infusionChoice: InfusionChoice) => void;

  showHeader: boolean;
  showDescription: boolean;

  isReadonly: boolean;
  proficiencyBonus: number;
  theme: CharacterTheme;
}
export default class FeatureSnippet extends React.PureComponent<Props> {
  static defaultProps = {
    infusionChoices: [],
    extraMeta: [],
    parseSnippet: true,
    showHeader: true,
    showDescription: false,
  };

  handleFeatureClick = (evt: React.MouseEvent): void => {
    const { onFeatureClick } = this.props;

    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    if (onFeatureClick) {
      onFeatureClick();
    }
  };

  getNonChoiceOptions = (): Array<Option> => {
    const { options, choices } = this.props;

    let optionLookup = keyBy(options, (option) => OptionUtils.getId(option));
    let usedOptionIds: Array<number> = [];

    choices.forEach((choice, idx) => {
      const type = ChoiceUtils.getType(choice);
      const optionValue = ChoiceUtils.getOptionValue(choice);

      switch (type) {
        case Constants.BuilderChoiceTypeEnum.RACIAL_TRAIT_OPTION:
        case Constants.BuilderChoiceTypeEnum.FEAT_OPTION:
        case Constants.BuilderChoiceTypeEnum.FEATURE_OPTION:
          if (optionValue !== null && optionLookup[optionValue]) {
            usedOptionIds.push(optionValue);
          }
          break;
      }
    });

    return options.filter(
      (option) => !usedOptionIds.includes(OptionUtils.getId(option))
    );
  };

  render() {
    const {
      heading,
      dataOriginExtra,
      extraMeta,
      choices,
      actions,
      spells,
      infusionChoices,
      className,
      snippetData,
      sourceId,
      sourcePage,
      classLevel,
      levelScale,
      children,
      abilityLookup,
      ruleData,
      subclass,
      options,
      feats,
      dataOriginRefData,
      onActionUseSet,
      onActionClick,
      onSpellClick,
      onSpellUseSet,
      onInfusionChoiceClick,
      showHeader,
      showDescription,
      isReadonly,
      proficiencyBonus,
      theme,
    } = this.props;

    let nonChoiceOptions = this.getNonChoiceOptions();
    let classNames: Array<string> = ["ct-feature-snippet", className];
    let metaItems: Array<React.ReactNode> = [];

    let levelScaleNode: React.ReactNode;
    if (levelScale) {
      levelScaleNode = (
        <span className="ct-feature-snippet__level-scale">
          {levelScale.description}
        </span>
      );
      metaItems.push(levelScaleNode);
    }

    metaItems.push(...extraMeta);

    let sourceNode: React.ReactNode;
    if (sourceId) {
      sourceNode = (
        <SourceAbbr
          theme={theme}
          sourceId={sourceId}
          sourcePage={sourcePage}
          ruleData={ruleData}
        />
      );
      metaItems.push(sourceNode);
    }

    const sourceDataLookup = RuleDataUtils.getSourceDataLookup(ruleData);

    return (
      <div className={classNames.join(" ")} onClick={this.handleFeatureClick}>
        {showHeader && (
          <div
            className={`ct-feature-snippet__heading ${
              theme?.isDarkMode ? "ct-feature-snippet__heading--dark-mode" : ""
            }`}
          >
            {heading}
            <span className="ct-feature-snippet__meta">
              {metaItems.map((metaItem, idx) => (
                <span
                  className={`ct-feature-snippet__meta-item ${
                    theme?.isDarkMode
                      ? "ct-feature-snippet__meta-item--dark-mode"
                      : ""
                  }`}
                  key={idx}
                >
                  {metaItem}
                </span>
              ))}
            </span>
            {dataOriginExtra && dataOriginExtra !== "Unknown" && (
              <div>
                <span className={styles.origin}>
                  <span className={styles.originLabel}>From</span>
                  <span className={styles.originName}>
                    {dataOriginExtra === "Unknown"
                      ? "Custom Addition"
                      : dataOriginExtra}
                  </span>
                </span>
              </div>
            )}
          </div>
        )}
        <div className="ct-feature-snippet__content">
          <Snippet
            snippetData={snippetData}
            levelScale={levelScale}
            classLevel={classLevel}
            parseSnippet={!showDescription}
            proficiencyBonus={proficiencyBonus}
            theme={theme}
          >
            {children}
          </Snippet>
        </div>
        {(choices.length > 0 ||
          actions.length > 0 ||
          spells.length > 0 ||
          nonChoiceOptions.length > 0) && (
          <div
            className="ct-feature-snippet__extra"
            style={
              theme?.isDarkMode ? { borderColor: theme.themeColor } : undefined
            }
          >
            <FeatureSnippetChoices
              choices={choices}
              options={options}
              onSpellClick={onSpellClick}
              onSpellUseSet={onSpellUseSet}
              onActionUseSet={onActionUseSet}
              onActionClick={onActionClick}
              ruleData={ruleData}
              abilityLookup={abilityLookup}
              subclass={subclass}
              feats={feats}
              sourceDataLookup={sourceDataLookup}
              classLevel={classLevel}
              snippetData={snippetData}
              dataOriginRefData={dataOriginRefData}
              showDescription={showDescription}
              isInteractive={!isReadonly}
              proficiencyBonus={proficiencyBonus}
              theme={theme}
            />
            <FeatureSnippetActions
              actions={actions}
              abilityLookup={abilityLookup}
              ruleData={ruleData}
              onActionClick={onActionClick}
              onActionUseSet={onActionUseSet}
              isInteractive={!isReadonly}
              proficiencyBonus={proficiencyBonus}
              theme={theme}
            />
            <FeatureSnippetSpells
              spells={spells}
              abilityLookup={abilityLookup}
              ruleData={ruleData}
              onSpellClick={onSpellClick}
              onSpellUseSet={onSpellUseSet}
              isInteractive={!isReadonly}
              dataOriginRefData={dataOriginRefData}
              proficiencyBonus={proficiencyBonus}
              theme={theme}
            />
            {nonChoiceOptions.length > 0 && (
              <div className="ct-feature-snippet__options">
                {nonChoiceOptions.map((option) => (
                  <FeatureSnippetOption
                    key={OptionUtils.getId(option)}
                    option={option}
                    onSpellClick={onSpellClick}
                    onSpellUseSet={onSpellUseSet}
                    onActionUseSet={onActionUseSet}
                    onActionClick={onActionClick}
                    abilityLookup={abilityLookup}
                    ruleData={ruleData}
                    sourceDataLookup={sourceDataLookup}
                    classLevel={classLevel}
                    snippetData={snippetData}
                    showDescription={showDescription}
                    isInteractive={!isReadonly}
                    dataOriginRefData={dataOriginRefData}
                    proficiencyBonus={proficiencyBonus}
                    theme={theme}
                  />
                ))}
              </div>
            )}
            <FeatureSnippetInfusionChoices
              infusionChoices={infusionChoices}
              onInfusionChoiceClick={onInfusionChoiceClick}
            />
          </div>
        )}
      </div>
    );
  }
}
