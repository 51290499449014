import { FC, ReactNode, useEffect, useState } from "react";

import { Button } from "@dndbeyond/ttui/components/Button";

interface Props {
  showLabel?: string;
  hideLabel?: string;
  className?: string;
  forceShow?: boolean;
  heading?: ReactNode;
}

export const CollapsibleContent: FC<Props> = ({
  showLabel = "Show More",
  hideLabel = "Show Less",
  forceShow,
  className,
  children,
  heading,
}) => {
  const [collapsed, setCollapsed] = useState(!forceShow);

  const handleToggleClick = (): void => {
    setCollapsed((prev) => !prev);
  };

  return (
    <div className={className}>
      <div>{heading}</div>
      {!collapsed && <div>{children}</div>}
      {!forceShow && (
        <Button
          variant="text"
          color="success"
          size="x-small"
          onClick={handleToggleClick}
        >
          {collapsed ? showLabel : hideLabel}
        </Button>
      )}
    </div>
  );
};
