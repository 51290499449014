import { visuallyHidden } from "@mui/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AbilitySummary,
  CampaignSummary,
  DarkChatBubbleSvg,
  DarkPlayButtonSvg,
  FeatureFlagContext,
  LightChatBubbleSvg,
  LightPlayButtonSvg,
  SavingThrowsSummary,
} from "@dndbeyond/character-components/es";
import {
  AbilityManager,
  DataOrigin,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";
import D6 from "@dndbeyond/fontawesome-cache/svgs/regular/dice-d6.svg";
import { GameLogNotificationWrapper } from "@dndbeyond/game-log-components";

import { Link } from "~/components/Link";
import { useAbilities } from "~/hooks/useAbilities";

import { sidebarActions } from "../../../../Shared/actions";
import {
  appEnvSelectors,
  characterRollContextSelectors,
} from "../../../../Shared/selectors";
import {
  NavigationUtils,
  PaneComponentEnum,
  PaneIdentifierUtils,
  PaneUtils,
} from "../../../../Shared/utils";
import MobileDivider from "../../../components/MobileDivider";
import SavingThrowsDetails from "../../../components/SavingThrowsDetails";
import Senses from "../../../components/Senses";
import SubsectionMobile from "../../../components/SubsectionMobile";

export default function MainMobile() {
  const abilities = useAbilities();
  const dispatch = useDispatch();
  const preferences = useSelector(rulesEngineSelectors.getCharacterPreferences);
  const savingThrowDiceAdjustments = useSelector(
    rulesEngineSelectors.getSavingThrowDiceAdjustments
  ); // TODO: GFS move to mangers
  const situationalBonusSavingThrowsLookup = useSelector(
    rulesEngineSelectors.getSituationalBonusSavingThrowsLookup
  ); // TODO: GFS move to mangers
  const passivePerception = useSelector(
    rulesEngineSelectors.getPassivePerception
  ); // TODO: GFS move to mangers
  const passiveInvestigation = useSelector(
    rulesEngineSelectors.getPassiveInvestigation
  ); // TODO: GFS move to mangers
  const passiveInsight = useSelector(rulesEngineSelectors.getPassiveInsight); // TODO: GFS move to mangers
  const senses = useSelector(rulesEngineSelectors.getSenseInfo); // TODO: GFS move to mangers
  const ruleData = useSelector(rulesEngineSelectors.getRuleData);
  const campaign = useSelector(rulesEngineSelectors.getCampaign);
  const theme = useSelector(rulesEngineSelectors.getCharacterTheme);
  const deathSaveInfo = useSelector(rulesEngineSelectors.getDeathSaveInfo);
  const diceEnabled = useSelector(appEnvSelectors.getDiceEnabled);
  const characterRollContext = useSelector(
    characterRollContextSelectors.getCharacterRollContext
  );
  const gameLog = useSelector(appEnvSelectors.getGameLog);

  const handleSensesManageShow = (): void => {
    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.SENSE_MANAGE));
  };

  const handleSavingThrowsClick = (): void => {
    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.SAVING_THROWS));
  };

  const handleAbilitySummaryClick = (ability: AbilityManager): void => {
    dispatch(
      sidebarActions.paneHistoryStart(
        PaneComponentEnum.ABILITY,
        PaneIdentifierUtils.generateAbility(ability)
      )
    );
  };

  const handleAbilityClick = (ability: AbilityManager): void => {
    dispatch(
      sidebarActions.paneHistoryStart(
        PaneComponentEnum.ABILITY_SAVING_THROW,
        PaneIdentifierUtils.generateAbilitySavingThrows(ability.getId())
      )
    );
  };

  const handleDataOriginClick = (dataOrigin: DataOrigin): void => {
    let component = PaneUtils.getDataOriginComponentInfo(dataOrigin);
    if (component.type !== PaneComponentEnum.ERROR_404) {
      dispatch(
        sidebarActions.paneHistoryPush(component.type, component.identifiers)
      );
    }
  };

  const handleCampaignShow = (): void => {
    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.CAMPAIGN));
  };

  const handleGameLogClick = (evt: React.MouseEvent): void => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.GAME_LOG));
  };

  return (
    <SubsectionMobile name="Main">
      <div
        className={`ct-main-mobile ${
          theme.isDarkMode ? "ct-main-mobile--dark-mode" : ""
        }`}
      >
        {campaign && (
          <div
            className={`ct-main-mobile__campaign ${
              theme.isDarkMode ? "ct-main-mobile__campaign--dark-mode" : ""
            }`}
          >
            <CampaignSummary
              campaign={campaign}
              onCampaignShow={handleCampaignShow}
              className={
                theme.isDarkMode
                  ? "ct-main-mobile__campaign-button--dark-mode"
                  : ""
              }
            />
            <Link
              href={NavigationUtils.getLaunchGameUrl(campaign)}
              target="maps"
              aria-label="Launch Game"
            >
              <div className="ct-character-header__group--game-log">
                <div className="ct-character-header-desktop__button-icon">
                  {theme.isDarkMode ? (
                    <LightPlayButtonSvg />
                  ) : (
                    <DarkPlayButtonSvg />
                  )}
                </div>
              </div>
            </Link>
            <GameLogNotificationWrapper
              themeColor={theme.themeColor}
              gameLogIsOpen={gameLog.isOpen}
              notificationOnClick={handleGameLogClick}
            >
              <div
                role="button"
                aria-roledescription="Game Log"
                className="ct-character-header__group--game-log ct-character-header__group--game-log-last"
                onClick={handleGameLogClick}
              >
                <div className="ct-character-header-desktop__button-icon">
                  <FeatureFlagContext.Consumer>
                    {({ tacticalMapsDmOnlyInvisDiceFlag }) =>
                      tacticalMapsDmOnlyInvisDiceFlag ? (
                        <D6
                          className={`ddbc-svg ${
                            theme.isDarkMode
                              ? "ddbc-gamelog-svg"
                              : "ddbc-svg--dark"
                          }`}
                        />
                      ) : theme.isDarkMode ? (
                        <LightChatBubbleSvg />
                      ) : (
                        <DarkChatBubbleSvg />
                      )
                    }
                  </FeatureFlagContext.Consumer>
                </div>
              </div>
            </GameLogNotificationWrapper>
          </div>
        )}
        <section className="ct-main-mobile__abilities">
          <h2 style={visuallyHidden}>Ability Scores</h2>
          {abilities.map((ability) => (
            <div className="ct-main-mobile__ability" key={ability.getStatKey()}>
              <AbilitySummary
                ability={ability}
                preferences={preferences}
                theme={theme}
                onClick={handleAbilitySummaryClick}
                diceEnabled={diceEnabled}
                rollContext={characterRollContext}
              />
            </div>
          ))}
        </section>
        <MobileDivider
          label="Saving Throws"
          onClick={handleSavingThrowsClick}
          theme={theme}
        />
        <section
          className={`ct-main-mobile__saving-throws ${
            theme.isDarkMode ? "ct-main-mobile__saving-throws--dark-mode" : ""
          }`}
        >
          <h2 style={visuallyHidden}>Saving Throws</h2>
          <SavingThrowsSummary
            abilities={abilities}
            situationalBonusSavingThrowsLookup={
              situationalBonusSavingThrowsLookup
            }
            onClick={handleAbilityClick}
            diceEnabled={diceEnabled}
            theme={theme}
            rollContext={characterRollContext}
          />
          <SavingThrowsDetails
            theme={theme}
            ruleData={ruleData}
            savingThrowDiceAdjustments={savingThrowDiceAdjustments}
            onDataOriginClick={handleDataOriginClick}
            deathSaveInfo={deathSaveInfo}
          />
        </section>
        <MobileDivider isEnd={true} theme={theme} />
        <MobileDivider
          label="Senses"
          onClick={handleSensesManageShow}
          theme={theme}
        />
        <h2 style={visuallyHidden}>Senses</h2>
        <Senses
          senses={senses}
          theme={theme}
          passiveInsight={Number(passiveInsight)}
          passiveInvestigation={Number(passiveInvestigation)}
          passivePerception={Number(passivePerception)}
          onClick={handleSensesManageShow}
        />
        <MobileDivider isEnd={true} theme={theme} />
      </div>
    </SubsectionMobile>
  );
}
