import { debounce, sortBy } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { LoadingPlaceholder } from "@dndbeyond/character-components/es";
import {
  CharacterTheme,
  DataOriginRefData,
  EntityValueLookup,
  FormatUtils,
  RuleDataUtils,
  rulesEngineSelectors,
  SpellManager as SpellManagerType,
} from "@dndbeyond/character-rules-engine/es";

import { Link } from "~/components/Link";

import DataLoadingStatusEnum from "../../constants/DataLoadingStatusEnum";
import { SpellsManagerContext } from "../../managers/SpellsManagerContext";
import { FilterUtils } from "../../utils";
import { ThemeButton } from "../common/Button";
import SpellManagerItem from "./SpellManagerItem";

interface Props {
  charClassId: number;
  characterClassId: number;
  shouldFetch?: boolean;
  hasActiveSpells?: boolean;

  isPrepareMaxed: boolean;
  isCantripsKnownMaxed: boolean;
  isSpellsKnownMaxed: boolean;
  addButtonText: string;
  enableAdd?: boolean;
  enablePrepare?: boolean;
  enableUnprepare?: boolean;
  enableSpellRemove?: boolean;
  showFilters?: boolean;
  showExpandedType?: boolean;
  showCustomize: boolean;
  buttonActiveStyle?: string;
  buttonUnprepareText?: string;
  entityValueLookup: EntityValueLookup;
  dataOriginRefData: DataOriginRefData;
  proficiencyBonus: number;
  theme: CharacterTheme;
}

// TODO: migrate this all into here
export default function SpellManagerContainer({
  showFilters = true,
  enableAdd = true,
  enableSpellRemove = true,
  showExpandedType = true,
  enablePrepare = true,
  enableUnprepare = true,
  shouldFetch = false,
  hasActiveSpells = false,
  charClassId,
  // TODO: get from manager?
  characterClassId,
  isPrepareMaxed,
  isCantripsKnownMaxed,
  isSpellsKnownMaxed,
  addButtonText,
  buttonActiveStyle,
  buttonUnprepareText,
  entityValueLookup,
  dataOriginRefData,
  showCustomize,
  proficiencyBonus,
  theme,
}: Props) {
  const ruleData = useSelector(rulesEngineSelectors.getRuleData);
  const spellCasterInfo = useSelector(rulesEngineSelectors.getSpellCasterInfo);
  const { spellsManager } = useContext(SpellsManagerContext);

  const [availableSpells, setAvailableSpells] = useState<
    Array<SpellManagerType>
  >([]);
  const [spells, setSpells] = useState<Array<SpellManagerType>>([]);
  const [loadingStatus, setLoadingStatus] = useState(
    DataLoadingStatusEnum.LOADING
  );
  const [filterLevels, setFilterLevels] = useState<Array<number>>([]);
  const [filterQuery, setFilterQuery] = useState("");

  const getData = useCallback(
    async function getData() {
      let classSpellMap = await spellsManager.getSpellShoppe();
      if (!classSpellMap[charClassId]) {
        classSpellMap = await spellsManager.getSpellShoppe(true);
      }
      setSpells(
        hasActiveSpells
          ? classSpellMap[charClassId].activeSpells
          : classSpellMap[charClassId].knownSpells
      );
      setAvailableSpells(
        shouldFetch ? classSpellMap[charClassId].availableSpells : []
      );
      setLoadingStatus(DataLoadingStatusEnum.LOADED);
    },
    [spellsManager, hasActiveSpells, shouldFetch, charClassId]
  );
  useEffect(() => {
    getData();
  }, [getData]);

  function getCombinedSpells(): Array<SpellManagerType> {
    // const remainingLazySpells = availableSpells.filter((spell) => !knownSpellIds.includes(spell.deriveKnownKey()));

    return sortBy(
      [...availableSpells, ...spells],
      [
        (spell) => spell.getLevel(),
        (spell) => spell.getName(),
        (spell) => spell.getExpandedDataOriginRef() !== null,
        (spell) => spell.getUniqueKey(),
      ]
    );
  }
  function getFilteredSpells(
    combinedSpells: Array<SpellManagerType>
  ): Array<SpellManagerType> {
    return combinedSpells.filter((spell) => {
      if (
        filterLevels.length !== 0 &&
        !filterLevels.includes(spell.getLevel())
      ) {
        return false;
      }

      if (
        filterQuery !== "" &&
        !FilterUtils.doesQueryMatchData(filterQuery, spell.getName())
      ) {
        return false;
      }

      return true;
    });
  }

  function handleFilterSpellLevel(level: number): void {
    setFilterLevels(
      filterLevels.includes(level)
        ? filterLevels.filter((l) => l !== level)
        : [...filterLevels, level]
    );
  }

  function handleQueryChange(evt: React.ChangeEvent<HTMLInputElement>): void {
    setFilterQuery(evt.target.value);
  }

  function renderSpellFilterUi(
    combinedSpells: Array<SpellManagerType>
  ): React.ReactNode {
    let activeSpellLevelCounts: Array<number> = [];
    let spellLevels: Array<number> = [];
    const maxSpellLevel = RuleDataUtils.getMaxSpellLevel(ruleData);
    for (let i = 0; i <= maxSpellLevel; i++) {
      activeSpellLevelCounts.push(0);
      spellLevels.push(i);
    }

    combinedSpells.forEach((spell) => {
      activeSpellLevelCounts[spell.getLevel()] += 1;
    });

    return (
      <div className="ct-spell-manager__filters">
        <div className="ct-spell-manager__filter">
          <div className="ct-spell-manager__filter-heading">Filter</div>
          <input
            type="search"
            className="ct-filter__query"
            value={filterQuery}
            onChange={handleQueryChange}
            placeholder={"Enter Spell Name"}
            spellCheck={false}
            autoComplete={"off"}
          />
        </div>
        <div className="ct-spell-manager__filter">
          <div className="ct-spell-manager__filter-heading">
            Filter By Spell Level
          </div>
          <div className="ct-spell-manager__filter-levels">
            {spellLevels.map((level) => {
              if (activeSpellLevelCounts[level] === 0) {
                return null;
              }

              let buttonLabel: React.ReactNode;
              if (level === 0) {
                buttonLabel = FormatUtils.renderSpellLevelAbbreviation(0);
              } else {
                buttonLabel = (
                  <div className="ct-spell-manager__filter-level-text">
                    <span className="ct-spell-manager__filter-level-number">
                      {level}
                    </span>
                    <span className="ct-spell-manager__filter-level-ordinal">
                      {FormatUtils.getOrdinalSuffix(level)}
                    </span>
                  </div>
                );
              }

              return (
                <div className="ct-spell-manager__filter-level" key={level}>
                  <ThemeButton
                    style={filterLevels.includes(level) ? "" : "outline"}
                    size="small"
                    onClick={handleFilterSpellLevel.bind(this, level)}
                  >
                    {buttonLabel}
                  </ThemeButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  function onSuccess() {
    getData();
  }
  function onFailure() {
    getData();
  }

  function renderUi(): React.ReactNode {
    const combinedSpells = getCombinedSpells();
    const filteredSpells = getFilteredSpells(combinedSpells);

    return (
      <React.Fragment>
        {showFilters && (
          <React.Fragment>
            {renderSpellFilterUi(combinedSpells)}
            <div className="ct-character-tools__marketplace-callout">
              Looking for something not in the list below? Unlock all official
              options in the <Link href="/marketplace">Marketplace</Link>.
            </div>
          </React.Fragment>
        )}
        {filteredSpells.map((spell, idx) => (
          <SpellManagerItem
            theme={theme}
            spell={spell}
            key={`${spell.getId()}-${idx}`}
            onPrepare={() => {
              spell.handlePrepare({ characterClassId }, onSuccess, onFailure);
            }}
            onUnprepare={() => {
              spell.handleUnprepare({ characterClassId }, onSuccess, onFailure);
            }}
            onRemove={() => {
              spell.handleRemove({ characterClassId }, onSuccess, onFailure);
            }}
            onAdd={() => {
              spell.handleAdd({ characterClassId }, onSuccess, onFailure);
            }}
            isPrepareMaxed={isPrepareMaxed}
            isCantripsKnownMaxed={isCantripsKnownMaxed}
            isSpellsKnownMaxed={isSpellsKnownMaxed}
            addButtonText={addButtonText}
            enableAdd={enableAdd}
            enablePrepare={enablePrepare}
            enableUnprepare={enableUnprepare}
            enableSpellRemove={enableSpellRemove}
            buttonUnprepareText={buttonUnprepareText}
            buttonActiveStyle={buttonActiveStyle}
            spellCasterInfo={spellCasterInfo}
            ruleData={ruleData}
            entityValueLookup={entityValueLookup}
            showExpandedType={showExpandedType ?? true}
            showCustomize={showCustomize}
            dataOriginRefData={dataOriginRefData}
            proficiencyBonus={proficiencyBonus}
          />
        ))}
      </React.Fragment>
    );
  }

  function renderLoading(): React.ReactNode {
    return <LoadingPlaceholder />;
  }

  let content: React.ReactNode;
  switch (loadingStatus) {
    case DataLoadingStatusEnum.LOADED:
      content = renderUi();
      break;

    case DataLoadingStatusEnum.LOADING:
    default:
      content = renderLoading();
      break;
  }

  return <div className="ct-spell-manager">{content}</div>;
}
