import axios, { Canceler } from "axios";
import React from "react";
import { useContext } from "react";

import {
  Checkbox,
  LoadingPlaceholder,
} from "@dndbeyond/character-components/es";
import {
  Container,
  ContainerUtils,
  CharacterTheme,
  Item,
  ItemUtils,
  RuleData,
  PartyInfo,
  InventoryManager,
  ItemManager,
  ContainerManager,
} from "@dndbeyond/character-rules-engine/es";

import { InventoryManagerContext } from "../../managers/InventoryManagerContext";
import { AppLoggerUtils, FilterUtils } from "../../utils";
import { ThemeButton } from "../common/Button";
import { EquipmentShopItem } from "./EquipmentShopItem";

interface Props {
  items?: Array<Item>;
  pageSize: number;
  // CAN THIS GO AS WELL!?
  ruleData: RuleData;
  proficiencyBonus: number;
  containers: Array<Container>;
  theme: CharacterTheme;
  limitAddToCurrentContainer: Container | null;
  partyInfo: PartyInfo | null;
  inventoryManager: InventoryManager;
}
interface State {
  query: "";
  shoppeContainer: ContainerManager | null;
  filteredItems: Array<Item>;
  currentPage: number;
  loading: boolean;
  loaded: boolean;
  filterTypes: Array<string>;
  filterQuery: string;
  filterProficient: boolean;
  filterBasic: boolean;
  filterMagic: boolean;
  filterContainer: boolean;
}
export class EquipmentShop extends React.PureComponent<Props, State> {
  static defaultProps = {
    pageSize: 12,
    limitAddToCurrentContainer: null,
  };

  // TODO: I am going to hold off on this

  loadItemsCanceler: null | Canceler = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      query: "",
      shoppeContainer: null,
      filteredItems: props.items ? props.items : [],
      currentPage: 0,
      loading: false,
      loaded: false,
      filterTypes: [],
      filterQuery: "",
      filterProficient: false,
      filterBasic: false,
      filterMagic: false,
      filterContainer: false,
    };
  }

  componentDidMount() {
    const { inventoryManager } = this.props;

    if (inventoryManager) {
      this.setState({
        loading: true,
      });

      // promise or callback vs async/await? geeze
      inventoryManager
        .getInventoryShoppe({
          onSuccess: (shoppeContainer: ContainerManager) => {
            this.setState({
              shoppeContainer,
              loading: false,
              loaded: true,
            });
          },
          additionalApiConfig: {
            cancelToken: new axios.CancelToken((c) => {
              this.loadItemsCanceler = c;
            }),
          },
        })
        .then((res) => {
          this.loadItemsCanceler = null;
          return res;
        })
        .catch(AppLoggerUtils.handleAdhocApiError);
    } else {
      this.setState({
        loaded: true,
      });
    }
  }

  componentWillUnmount(): void {
    if (this.loadItemsCanceler !== null) {
      this.loadItemsCanceler();
    }
  }

  isFiltered = (): boolean => {
    const {
      filterQuery,
      filterTypes,
      filterProficient,
      filterMagic,
      filterBasic,
      filterContainer,
    } = this.state;

    if (filterProficient || filterMagic || filterBasic || filterContainer) {
      return true;
    }

    if (filterQuery) {
      return true;
    }

    if (filterTypes.length) {
      return true;
    }

    return false;
  };

  getFilteredItems = (
    combinedItems: Array<ItemManager>
  ): Array<ItemManager> => {
    const {
      filterQuery,
      filterTypes,
      filterProficient,
      filterMagic,
      filterBasic,
      filterContainer,
    } = this.state;

    return combinedItems.filter((item) => {
      if (
        filterProficient &&
        (item.isWeaponContract() || item.isArmorContract()) &&
        !item.hasProficiency()
      ) {
        return false;
      }

      let filterType = item.getDefintionFilterType();
      if (
        filterTypes.length !== 0 &&
        filterType !== null &&
        !filterTypes.includes(filterType)
      ) {
        return false;
      }

      let searchTags: Array<string> = [];
      if (item.isGearContract()) {
        let subType = item.getSubType();
        if (subType) {
          searchTags.push(subType);
        }
      }
      if (
        filterQuery !== "" &&
        !FilterUtils.doesQueryMatchData(filterQuery, item.getName(), searchTags)
      ) {
        return false;
      }

      if (filterMagic && !filterBasic && !item.isMagic()) {
        return false;
      }

      if (filterBasic && !filterMagic && item.isMagic()) {
        return false;
      }

      if (
        filterContainer &&
        !filterBasic &&
        !filterMagic &&
        !item.isContainer()
      ) {
        return false;
      }

      return true;
    });
  };

  handlePageMore = (): void => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  handleQueryChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      filterQuery: evt.target.value,
      currentPage: 0,
    });
  };

  handleFilterItemType = (type: string): void => {
    this.setState((prevState: State) => ({
      filterTypes: prevState.filterTypes.includes(type)
        ? prevState.filterTypes.filter((t) => t !== type)
        : [...prevState.filterTypes, type],
      currentPage: 0,
    }));
  };

  handleProficientToggle = (): void => {
    this.setState((prevState: State) => ({
      filterProficient: !prevState.filterProficient,
      currentPage: 0,
    }));
  };

  handleBasicToggle = (): void => {
    this.setState((prevState: State) => ({
      filterBasic: !prevState.filterBasic,
      currentPage: 0,
    }));
  };

  handleMagicToggle = (): void => {
    this.setState((prevState: State) => ({
      filterMagic: !prevState.filterMagic,
      currentPage: 0,
    }));
  };

  handleContainerToggle = (): void => {
    this.setState((prevState: State) => ({
      filterContainer: !prevState.filterContainer,
      currentPage: 0,
    }));
  };

  renderFilterUi = (): React.ReactNode => {
    const {
      filterQuery,
      filterTypes,
      filterBasic,
      filterMagic,
      filterProficient,
      filterContainer,
    } = this.state;

    const itemTypes: Array<string> = [
      "Armor",
      "Potion",
      "Ring",
      "Rod",
      "Scroll",
      "Staff",
      "Wand",
      "Weapon",
      "Wondrous item",
      "Other Gear",
    ];

    return (
      <div className="ct-equipment-shop__filters">
        <div className="ct-equipment-shop__filter">
          <label
            className="ct-equipment-shop__filter-heading"
            htmlFor="equipment-shop-filter-input"
          >
            Filter
          </label>
          <input
            id="equipment-shop-filter-input"
            type="search"
            className="ct-filter__query"
            value={filterQuery}
            onChange={this.handleQueryChange}
            placeholder="Weapon, Longsword, Vorpal Longsword, etc."
            spellCheck={false}
            autoComplete="off"
          />
        </div>
        <div className="ct-equipment-shop__filter">
          <div className="ct-equipment-shop__filter-heading">
            Filter By Type
          </div>
          <div className="ct-equipment-shop__levels">
            {itemTypes.map((itemType) => (
              <div className="ct-equipment-shop__level" key={itemType}>
                <ThemeButton
                  style={filterTypes.includes(itemType) ? "" : "outline"}
                  onClick={this.handleFilterItemType.bind(this, itemType)}
                  block={true}
                  size="small"
                >
                  {itemType === "Wondrous item" ? "Wondrous" : itemType}
                </ThemeButton>
              </div>
            ))}
          </div>
        </div>
        <div className="ct-equipment-shop__filters-toggles">
          <div className="ct-equipment-shop__filter-toggle">
            <Checkbox
              stopPropagation={true}
              initiallyEnabled={filterProficient}
              onChange={this.handleProficientToggle}
              label="Proficient"
            />
          </div>
          <div className="ct-equipment-shop__filter-toggle">
            <Checkbox
              stopPropagation={true}
              initiallyEnabled={filterBasic}
              onChange={this.handleBasicToggle}
              label="Common"
            />
          </div>
          <div className="ct-equipment-shop__filter-toggle">
            <Checkbox
              stopPropagation={true}
              initiallyEnabled={filterMagic}
              onChange={this.handleMagicToggle}
              label="Magical"
            />
          </div>
          <div className="ct-equipment-shop__filter-toggle">
            <Checkbox
              stopPropagation={true}
              initiallyEnabled={filterContainer}
              onChange={this.handleContainerToggle}
              label="Container"
            />
          </div>
        </div>
      </div>
    );
  };

  renderPager = (
    filteredItems: Array<ItemManager>,
    totalItems: number
  ): React.ReactNode => {
    if (!this.isFiltered()) {
      return null;
    }

    if (filteredItems.length >= totalItems) {
      return null;
    }

    return (
      <div className="ct-equipment-shop__pager">
        <ThemeButton block={true} onClick={this.handlePageMore}>
          Load More
        </ThemeButton>
      </div>
    );
  };

  renderPagedListing = (filteredItems: Array<ItemManager>): React.ReactNode => {
    const { currentPage } = this.state;
    const {
      pageSize,
      ruleData,
      proficiencyBonus,
      containers,
      theme,
      limitAddToCurrentContainer,
      partyInfo,
    } = this.props;

    if (!this.isFiltered()) {
      return null;
    }

    const pagedFilteredItems: Array<ItemManager> = filteredItems.slice(
      0,
      (currentPage + 1) * pageSize
    );

    return (
      <div className="ct-equipment-shop__items">
        {pagedFilteredItems.length ? (
          pagedFilteredItems.map((item, idx) => {
            let filteredContainers = containers;
            const isContainer = item.isContainer();
            const isPack = item.isPack();
            if (isContainer || isPack) {
              filteredContainers = containers.filter(
                (container) =>
                  ContainerUtils.isCharacterContainer(container) ||
                  ContainerUtils.isPartyContainer(container)
              );
            } else if (limitAddToCurrentContainer) {
              filteredContainers = [limitAddToCurrentContainer];
            }
            return (
              <EquipmentShopItem
                key={`${item.getDefinitionId()}-${idx}`}
                containers={filteredContainers}
                theme={theme}
                item={item}
                ruleData={ruleData}
                proficiencyBonus={proficiencyBonus}
                partyInfo={partyInfo}
              />
            );
          })
        ) : (
          <div className="ct-equipment-shop__empty">No Results Found</div>
        )}
      </div>
    );
  };

  renderUi = (): React.ReactNode => {
    const {
      filterTypes,
      filterQuery,
      filterProficient,
      filterBasic,
      filterMagic,
      filterContainer,
      currentPage,
    } = this.state;
    const { pageSize } = this.props;

    const itemData = this.state.shoppeContainer?.getInventoryItems({
      filterOptions: {
        filterTypes,
        filterQuery,
        filterProficient,
        filterBasic,
        filterMagic,
        filterContainer,
      },
      paginationOptions: {
        currentPage,
        pageSize,
      },
      isShoppe: true,
    });

    const items = itemData?.items ?? [];
    const totalItems = itemData?.totalItems ?? 0;

    return (
      <React.Fragment>
        {this.renderFilterUi()}
        {this.renderPagedListing(items)}
        {this.renderPager(items, totalItems)}
      </React.Fragment>
    );
  };

  renderLoading = (): React.ReactNode => {
    return <LoadingPlaceholder />;
  };

  render() {
    const { loaded, loading } = this.state;

    return (
      <div className="ct-equipment-shop">
        {loading && this.renderLoading()}
        {!loading && loaded && this.renderUi()}
      </div>
    );
  }
}

export default function EquipmentShopContainer(props) {
  const { inventoryManager } = useContext(InventoryManagerContext);
  return <EquipmentShop inventoryManager={inventoryManager} {...props} />;
}
